@import '../variables';
@import 'typography-mixins';

$spacing-collection: ($spacing-0, $spacing-01, $spacing-02, $spacing-03, $spacing-04, $spacing-05, $spacing-06, $spacing-07, $spacing-08);
$type-spacing-collection: ('m', 'p', 'h');

@for $s from 0 to length($spacing-collection) {
  @for $t from 0 to length($type-spacing-collection) {
    @if nth($type-spacing-collection, $t + 1) == m {
      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)} {
        margin: nth($spacing-collection, $s + 1);
      }
      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-t {
        margin-top: nth($spacing-collection, $s + 1);
      }
      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-r {
        margin-right: nth($spacing-collection, $s + 1);
      }
      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-b {
        margin-bottom: nth($spacing-collection, $s + 1);
      }
      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-l {
        margin-left: nth($spacing-collection, $s + 1);
      }
    } @else if nth($type-spacing-collection, $t + 1) == p {
      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)} {
        padding: nth($spacing-collection, $s + 1);
      }
      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-t {
        padding-top: nth($spacing-collection, $s + 1);
      }
      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-r {
        padding-right: nth($spacing-collection, $s + 1);
      }
      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-b {
        padding-bottom: nth($spacing-collection, $s + 1);
      }
      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-l {
        padding-left: nth($spacing-collection, $s + 1);
      }
    } @else if nth($type-spacing-collection, $t + 1) == h {
      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)} {
        height: nth($spacing-collection, $s + 1);
      }
    }
  }
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Noto Sans'),
    local('NotoSans'),
    url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr5TRA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
    U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'EYInterstate Light';
  src: local('EYInterstate Light');
  src:
    url('/assets/fonts/EYInterstate-Light.woff2') format('woff2'),
    url('/assets/fonts/EYInterstate-Light.eot'),
    url('/assets/fonts/EYInterstate-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/EYInterstate-Light.ttf') format('truetype'),
    url('/assets/fonts/EYInterstate-Light.woff') format('woff'),
    url('/assets/fonts/EYInterstate-Light.svg') format('svg');

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'EYInterstate';
  src: local('EYInterstate');
  src:
    url('/assets/fonts/EYInterstate-Regular.woff2') format('woff2'),
    url('/assets/fonts/EYInterstate-Regular.woff') format('woff'),
    url('/assets/fonts/EYInterstate-Regular.ttf') format('truetype'),
    url('/assets/fonts/EYInterstate-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/EYInterstate-Regular.woff2') format('woff2'),
    url('/assets/fonts/EYInterstate-Regular.svg') format('svg');
}

@font-face {
  font-family: 'EYInterstate-Bold';
  src: local('EYInterstate-Bold');
  src:
    url('/assets/fonts/EYInterstate-Bold.woff2') format('woff2'),
    url('/assets/fonts/EYInterstate-Bold.woff') format('woff'),
    url('/assets/fonts/EYInterstate-Bold.ttf') format('truetype'),
    url('/assets/fonts/EYInterstate-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/EYInterstate-Bold.woff2') format('woff2'),
    url('/assets/fonts/EYInterstate-Bold.svg') format('svg');
}

/* Initial Components*/
/* begin global button styles */
@mixin icons-buttons($icon, $font-size, $margin: '0 0.5rem 0 0') {
  margin: $margin;
  font-family: 'Material Icons';
  font-size: $font-size;
  content: $icon;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  line-height: inherit;
  vertical-align: bottom;
}
/* the btn class should be universal css styling for most buttons */
.btn {
  @include fonts-col__sub-nav-selected-state;
  color: $color-ey-confident-grey;
  transition: all 0.3s ease;
  border-radius: 0;
  //all buttons need to have a transparent border to prevent bouncing and support in IE11
  //this will default value for all btn, to override outside btn, use border-color only
  border: 0.0625rem solid transparent;
  height: 2.813rem;
  vertical-align: middle;
  padding-top: 0.0625rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 0;
  margin: 0.3125rem 2.188rem;
  /*   buttons should not be smaller then 6.875rem */
  min-width: 6.875rem;
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;

  /* fighting bootstrp in IE11  */
  &:hover,
  &.hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: $color-white;
    background-color: $color-ey-confident-grey;
    border-color: 0.0625rem solid transparent; //default - prevent bouncing
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  /* disabled buttons start ------------------------------*/
  &.disabled,
  &:disabled {
    &,
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: $color-ey-grey-1;
      background-color: #eaeaf2; //no token
      border: 0.0625rem solid transparent; /* all buttons need to have a transparent border to prevent bouncing and support in IE11*/
      opacity: 1;
      cursor: not-allowed;
    }
  }

  /* all buttons that hosts icons need more margin */
  i {
    margin: 0 0.5rem 0 0;
  }

  & .material-icons {
    font-size: $font-size;
    vertical-align: bottom;
    line-height: inherit;
  }
}

/* button primary start --------------------------------------- */
.btn--primary {
  color: $color-ey-confident-grey;
  background-color: $color-ey-yellow;
}

/* button primary (dark option) start ------------------------- */
.btn--primary-dark {
  color: $color-white;
  background-color: $color-ey-confident-grey;
  &:hover,
  &.hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    &:not(:disabled):not(.disabled) {
      color: $color-ey-confident-grey;
      background-color: $color-ey-yellow;
    }
  }
}

/* button secondary starts here ------------------------ */
.btn--secondary {
  background: none;
  color: $color-ey-confident-grey;
  border-color: $color-ey-confident-grey;
}

/* button tertiary starts here ------------------------ */
.btn--tertiary,
.btn--icon {
  background: none;
  color: $color-ey-confident-grey;
  border-color: $color-border-2;
  -webkit-box-shadow: none;
  box-shadow: none;
  /* all buttons active should have a grey border on selection/active */
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background: $color-ey-grey-4;
    color: $color-ey-confident-grey;
    border-color: $color-border;
  }
}

/* button previous & next starts here ----------------- */
@mixin btn--previous-next($type) {
  // background: $color-white;
  border-color: $color-ey-confident-grey;
  padding: 0;
  @if $type==right {
    min-width: 5.0625rem;
    padding-left: 0.875rem;
    &::after {
      @include icons-buttons('chevron_right', 1.5rem);
    }
  } @else if $type==left {
    min-width: 5.125rem;
    padding-right: 0.875rem;
    &::before {
      @include icons-buttons('chevron_left', 1.5rem, '0 0 0 0.5rem');
    }
  }
  $parentClass: &;
  &#{$parentClass}-primary {
    border-color: transparent;
    background: $color-ey-yellow;
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: $color-white;
      background-color: $color-ey-confident-grey;
    }
  }
}
.btn--next {
  @include btn--previous-next('right');
}
.btn--back {
  @include btn--previous-next('left');
}
.btn--final-step {
  background: $color-ey-yellow;
  min-width: 4.875rem;
  padding: 0;
  &:hover,
  &.hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    border-color: transparent;
  }
}
/* button approved & deny starts here ----------------- */
@mixin btn--approved-deny($active-bgColor, $icon) {
  border-color: $color-ey-confident-grey;
  min-width: 9.9375rem;
  &:before {
    @include icons-buttons($icon, 1.5rem);
  }
  // &:hover ==> use default
  &:focus {
    border-color: transparent;
    background: $active-bgColor;
    color: $color-white;
  }
  //&:disabled ==> use default
}
.btn--approved {
  @include btn--approved-deny($color-green-06, 'check');
}
.btn--deny {
  @include btn--approved-deny($color-red-06, 'block');
}
/* button progress starts here ------------------------ */
@mixin btn--progress {
  &.in-progress:after,
  &.finished:after {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    padding-top: inherit;
    color: $color-white;
    left: 0;
  }

  & .tz-bar {
    background-color: $color-ey-confident-grey;
    height: 0.1875rem;
    bottom: -0.0625rem;
    left: -0.0625rem;
    width: 0;
    position: absolute;
    z-index: 1;
    -webkit-transition:
      width 0.5s,
      height 0.5s,
      background-color 0ms,
      color 0ms;
    -moz-transition:
      width 0.5s,
      height 0.5s,
      background-color 0ms,
      color 0ms;
    transition:
      width 0.5s,
      height 0.5s,
      background-color 0ms,
      color 0ms;
  }

  & .tz-bar.background-horizontal {
    height: 100%;
  }

  & .tz-bar.background-vertical {
    height: 0;
    top: 0;
    width: 100%;
  }
}

.btn--progress {
  @include btn--progress;
  position: relative;
  $parentClass: &;
  &#{$parentClass}-primary {
    background-color: $color-ey-yellow;
    color: $color-ey-confident-grey;
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $color-ey-confident-grey;
      color: $color-white;
      & .tz-bar {
        background-color: $color-white;
      }
    }
  }
  &#{$parentClass}-primary-dark {
    background-color: $color-ey-confident-grey;
    color: $color-white;
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $color-ey-yellow;
      color: $color-ey-confident-grey;
      & .tz-bar {
        background-color: $color-ey-confident-grey;
      }
    }
    & .tz-bar {
      background-color: $color-white;
    }
  }
  &#{$parentClass}-secondary {
    background: $color-white;
    border-color: $color-ey-confident-grey;
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $color-ey-confident-grey;
      color: $color-white;
      border-color: transparent;
      & .tz-bar {
        background-color: $color-white;
      }
    }
  }
  &#{$parentClass}-tertiary {
    position: relative;
    background-color: $color-white;
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $color-ey-grey-4;
      color: $color-ey-confident-grey;
      border-color: $color-border;
    }
  }
}

/* button utilitiy starts here ------------------------- */
.btn--utility {
  border-color: transparent;
  background: $color-ey-grey-2;
  color: $color-ey-confident-grey;
}

/* button delete, warning starts here ------------------------ */
.btn--delete,
.btn--warning {
  background: $color-red-06;
  color: $color-white;
}

/* start button link css ---------------------------- */

.btn--link {
  background-color: transparent;
  font-family: $font-family-bold;
  font-size: 0.875rem;
  padding: 0;
  vertical-align: middle;
  span {
    font-family: $font-family-bold !important;
  }
  i {
    font-size: 1rem;
  }

  &:hover,
  &.hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    &:not(:disabled):not(.disabled) {
      color: $color-ey-confident-grey;
      background-color: transparent;
      span {
        text-decoration: underline;
      }
    }
  }

  &:disabled,
  &.disabled {
    background-color: transparent;
    color: $color-ey-grey-1;
    &:hover,
    &.hover,
    &:focus,
    &.focus {
      background-color: transparent;
    }
  }
}

.btn--link-blue {
  color: $color-ey-link;

  &:hover,
  &.hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    &:not(:disabled):not(.disabled) {
      color: $color-ey-link;
    }
  }
}

.btn--icon {
  width: 3.125rem;
  height: 3.25rem;
  min-width: 3.125rem;
  min-height: 3.25rem;
  padding: 0;
  & i {
    margin: 0;
  }
}

// Checkbox Borders: [*__checkmark, *__label, *__input]
//  - All parameters have defaults
//  - Parameters:
//    - $color {string} : Default Border color
//    - $color-disabled {string} : Disabled Border color
//    - $color-error {string} : Error Border color
@mixin Checkbox-border($color: $chk-border-default, $color-disabled: $chk-border-disabled, $color-error: $chk-border-error) {
  $parentClass: &;
  //#{$parentClass}

  // default  ----------
  &__checkmark {
    border: 0.0625rem solid $color;
  }

  // Disabled  ----------
  &__checkmark {
    #{$parentClass}__input:disabled ~ &,
    #{$parentClass}--disabled & {
      border: 0.0625rem solid $color-disabled;
    }
  }

  // Error  ----------
  &__checkmark {
    #{$parentClass}__input[error] ~ &,
    #{$parentClass}--error & {
      border: 0.0625rem solid $color-error;
    }
  }
}

// Checkbox Colors: [*__checkmark, *__label, *__input]
//  - All parameters have defaults
//  - Parameters:
//    - $color {string} : Text color
//    - $color-bg {string} : Background color (Default/Primary)
//    - $color-bg-alt {string} : Background color (Alternative)
//    - $color-error {string} : Error label/msg/icon color
@mixin Checkbox-color(
  $color: $chk-color-default,
  $color-bg: $chk-bg-default,
  $color-bg-alt: $chk-bg-alternative,
  $color-disabled: $chk-color-disabled,
  $color-error: $chk-error-color
) {
  $parentClass: &;
  //#{$parentClass}

  // default  ----------
  &__label {
    color: $color;
  }
  &__checkmark {
    background-color: transparent;
    #{$parentClass}__input:checked ~ & {
      background-color: $color-bg;
    }
  }

  // Disabled  ----------
  &__label {
    #{$parentClass}__input:disabled ~ &,
    #{$parentClass}--disabled & {
      color: $color-disabled;
    }
  }
  &__checkmark {
    #{$parentClass}__input:disabled ~ &,
    #{$parentClass}--disabled & {
      background-color: $color-bg-alt;
    }
  }

  // Error  ----------
  // &__label // default color
  &__checkmark {
    #{$parentClass}--error #{$parentClass}__input:checked ~ &,
    #{$parentClass}__input:checked[error] ~ & {
      background-color: $color-error;
    }
  }
}

/* checkbox ===================================== */

//Sub nav selected title
.checkbox-title-section {
  text-align: left;
  margin: 0 0 1.25rem 0;
}
/* Customize the label (the container) */
.checkbox-container-section {
  margin-right: $spacing-03;
}

.checkbox {
  @include Checkbox-border;
  @include Checkbox-color;

  ///dark theme ---------
  // .dark & {
  //   @include Checkbox-border(......);
  //   @include Checkbox-color(.......);
  // }

  position: relative;
  cursor: pointer;
  font-size: 0.875rem;
  user-select: none;
  width: 100%;
  padding-right: 1rem;
  padding-left: 0.9375rem;
  margin: 0 auto;
  &.focus {
    outline: 0.0625rem solid #aaaab1;
    outline-offset: 0.375rem;
  }
  .data-table-standard-container &.focus {
    outline: none;
    outline-offset: 0;
    .checkbox__checkmark {
      outline: 0.09rem solid -webkit-focus-ring-color;
      outline-offset: 0.375rem;
    }
  }
}

/* Hide the browser's default checkbox */

.checkbox__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
}

.checkbox__input:disabled ~ .checkbox__checkmark,
.checkbox__input:checked:disabled ~ .checkbox__checkmark,
.checkbox:hover .checkbox__input:disabled ~ .checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  cursor: not-allowed;
}

/* Style the checkmark/indicator */
.checkbox__checkmark:after {
  content: '';
  /*we should use \e118*/
  position: absolute;
  left: 0.375rem;
  top: 0.0625rem;
  width: 0.375rem;
  height: 0.75rem;
  border: solid white;
  border-width: 0 0.125rem 0.125rem 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: none;

  /* Show the checkmark when checked */
  .checkbox__input:checked ~ & {
    display: block;
  }
}

/*NOTE: .checkbox is added to override ".content-padding p" style*/
/*NOTE: .checkbox is also used by multi-select's checkbox items*/
.checkbox {
  margin: 0 auto 1.25rem 0;
  .checkbox__label {
    @include fonts-col__instructional-text(0.9375rem);
    font-size: 0.875rem !important;
    position: relative;
    display: inline;
    left: 0.875rem;
    top: 0;
    padding: 0 !important;
    vertical-align: text-top;
  }
  &__label {
    vertical-align: middle;
    position: static;
    margin-right: 1rem;
  }
  &--disabled .checkbox__label {
    cursor: not-allowed;
  }
  &--error {
    margin-bottom: 0;
    .checkbox__label {
      margin: 0.3125rem 0 0 0.125rem;
    }
  }
}

/* Style the error indicator*/

.checkbox__errormsg {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: $chk-error-color;
  vertical-align: top;
  display: inline-block;
  margin: 0.25rem 0 1.25rem 0.5625rem;
}

.checkbox__errormsg .material-icons {
  font-size: 1rem;
  color: $chk-error-color;
  vertical-align: middle;
  padding-right: 0.5rem;
}

// Dropdown Colors:
//  - All parameters have defaults
//  - Parameters:
//    - $color {string} : Text color
//    - $color-bg {string} : Background color (Default/Primary)
//    - $color-bg-alt {string} : Background color (Alternative)
//    - $color-error {string} : Error label/msg/icon color
@mixin dropdown-color(
  $color: $form-color-default,
  $color-bg: $form-dpdn-bg-default,
  $color-bg-alt: $form-dpdn-alternative,
  $color-error: $form-error-color
) {
  $parentClass: &;

  .dropdown-menu {
    .dropdown-item,
    .dropdown-header {
      color: $color;
      background: $color-bg;
      &.checkbox {
        background: $color-bg;
        @include dropdown-state($color, $color-bg-alt);
      }
      &.checkbox.checked {
        @include dropdown-state($color, $color-bg-alt, false);
      }
    }
    & .btn.dropdown__group--btn {
      background: $color-bg;
      @include dropdown-state($color, $color-bg-alt);
    }
  }
}

@mixin dropdown-state($color, $bk-color, $state: true) {
  @if $state {
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: $color;
      background: $bk-color;
      outline: none;
    }
  } @else {
    background: $bk-color;
    outline: none;
  }
}

//////////////////////////////////////////////////////////////////////////

/*------- DROPDOWN MAIN STYLE ---css start here----------*/

$dropdown-width: 18.75rem;
$dropdown-height: 2.813rem;
@mixin dropdown-outline {
  outline: none;
  border: 0.0625rem solid $form-border-default;
  border-radius: 0;
  &[x-placement='bottom-start'] {
    border-top-width: 0;
  }
}

/*-- DS Global Style for .dropdown-toggle--*/

.dropdown__expand_less,
.dropdown__expand_more {
  color: $color-ey-confident-grey;
  position: absolute;
  top: 0.625rem;
  right: 0.375rem;
  cursor: pointer;
  display: none;
}

.dropdown {
  @include dropdown-color;

  // Dark theme ------
  .dark & {
    @include dropdown-color(
      $color: $darkform-color-default,
      $color-bg: $darkform-dpdn-bg-default,
      $color-bg-alt: $darkform-dpdn-alternative,
      $color-error: $darkform-error-color
    );
  }

  .dropdown-toggle {
    position: relative;
    height: $dropdown-height;
    min-height: $dropdown-height;
    width: $dropdown-width;
    // overflow: hidden;
    text-decoration: none;
    //for d-flex
    display: -webkit-box;
    display: flex;
    //for flex-column
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    //for mb-auto
    margin-bottom: auto;

    &[aria-expanded='false'] {
      &:not([aria-disabled='true']) .dropdown__expand_more {
        display: block;
      }
    }

    &[aria-expanded='true'] {
      &:not([aria-disabled='true']) .dropdown__expand_less {
        display: block;
      }
    }

    &::after {
      display: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  /*-- DS Global Style for .dropdown-menu--*/
  .dropdown-menu {
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
    @include dropdown-outline;
    .dropdown-item {
      @include fonts-col__instructional-text;
      color: $color-ey-confident-grey;
      line-height: 2.5rem !important;
      height: $dropdown-height;
      width: calc(#{$dropdown-width} - 0.12rem);
      overflow: hidden;
      background: $color-white;
      display: inherit;
      &.checkbox {
        line-height: 2.5rem !important;
      }
      &.hide {
        display: none;
      }
    }
  }

  /*override style of text input component under dropdown block*/

  .textinput-group__textinput {
    // font style set on _inputs.scss
    min-height: 100%;
    max-height: 100%;
  }

  .textinput-group__errmsg {
    @include fonts-col__field-labels-on-focus;
    position: inherit;
    min-height: 1.25rem;
    line-height: 1.25rem;
    top: -0.1rem; //override
    &:before {
      transform: translateX(-1.4rem);
    }
  }
}

/*-------END OF: DROPDOWN MAIN STYLE -----------*/
/*----------------------------------------------*/

/* ++++++++++ Multi-Select STYLES ++++++++++ */
// Note: this component is also using dropdown style, checkbox style and it corresponding script

.dropdown.dropdown--multi-select {
  .dropdown-toggle {
    &.dropdown-toggle--counter {
      .textinput-group__textinput {
        padding-left: 3.15rem;
        padding-right: 2.1875rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .dropdown-menu {
    background-color: transparent;
  }
  .dropdown__items {
    max-height: 15rem;
    overflow: overlay;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      background: $color-white;
      border-radius: 0;
      box-shadow: inset 0 0.0625rem 0.1875rem 0 rgba(207, 207, 207, 0.5); //no token
      width: 0.625rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #a8a8a8; //no token
      border-radius: 0.375rem;
      border: 0.125rem solid $color-white;
    }
  }

  .dropdown-item {
    height: 2.5rem;
  }
}

.pill.pill--dropdown {
  position: absolute;
  left: 0;
  top: 0.2rem;
  background-color: $color-ey-confident-grey;
  padding-left: 0.375rem;
  padding-right: 0.25rem;
  min-width: 0;
  display: none;
  margin: 0;

  .dropdown-toggle.dropdown-toggle--counter .asterisk & {
    top: 1.3rem;
  }

  .dropdown-toggle.dropdown-toggle--counter & {
    display: block;
    top: 1.3rem;
    left: 0.6rem;
  }

  .Pill__label {
    width: 0.625rem;
    height: 0.75rem;
    color: $color-white;
    font-size: 0.625rem;
    font-family: $font-family-bold !important;
    font-weight: bold;
    letter-spacing: 0;
    padding-right: 0.0625rem;
  }

  .pill__close {
    padding-left: 0.0625rem;
    .material-icons {
      font-size: 0.75rem;
      vertical-align: text-bottom;
      cursor: pointer;
    }
  }

  button.pill__close {
    // Override for <button>
    background-color: transparent;
    color: $color-white;
    border: none;
    padding: 0;
  }
}

/* Overriding Checkbox style for dropdown component */
.checkbox.dropdown-item {
  margin-bottom: 0;
  .checkbox__checkmark {
    top: 0.6875rem;
    left: 1.188rem;
  }
  .checkbox__label {
    left: 2.063rem;
    top: -0.1875rem;
  }
}
/* Overriding btn style for dropdown component */
.btn {
  .dropdown-menu & {
    min-width: 9.125rem;
    margin: 0;
  }
}
.dropdown__Buttons {
  background-color: $color-white;
  padding: 0.46875rem;
  margin: 0;
  @include dropdown-outline;
  border-width: 0;
  border-top-width: 0.0625rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;

  .btn {
    min-width: 0;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    margin: 0.46875rem;
  }
}

/* Overriding style for hierarchical dropdown component */
.dropdown__group {
  //d-flex flex-row align-items-center
  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  flex-direction: row !important;
  -webkit-box-align: center !important;
  align-items: center !important;

  .dropdown__group--btn {
    display: unset;
    min-width: 1rem;
    height: 2.5rem;
    font-size: 1rem;
    line-height: 1rem;
    padding: 0 0.375rem 0 1.313rem;

    &.expand {
      .add {
        display: none;
      }
      .minus {
        display: unset;
      }
    }
    &.collapse {
      .add {
        display: unset;
      }
      .minus {
        display: none;
      }
    }
    .material-icons {
      margin: 0;
    }
  }

  .dropdown-header.checkbox {
    width: unset;
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    height: 2.5rem;
    margin: 0 auto 0 0;
    line-height: 2.5rem !important;

    .checkbox__checkmark {
      top: 0.6875rem;
      left: 0.375rem;
    }
    .checkbox__label {
      left: 1.375rem;
      line-height: 0.75rem !important;
    }
  }
}
.dropdown__group-child {
  &.hide {
    display: none;
  }
  .checkbox.dropdown-item {
    .checkbox__checkmark {
      left: 4.75rem;
    }
    .checkbox__label {
      margin: 0;
      padding: 0;
      left: 5.625rem;
    }
  }
}

/*-------END OF: Multi-Select STYLES -----------*/
/*----------------------------------------------*/

/*--for wrapper element only--*/
.wrapper-label {
  width: 2.813rem;
  height: 2.813rem;
  margin-right: 1.375rem;
  font-size: 0.625rem;
  color: $color-ey-confident-grey;
  text-align: right;
}

/*--main group of the component (DIV)*/
$single-select-width: 18.75rem;
$single-select-height: 3.125rem;

// /* --> Reference in inputs component */
// Form Borders: [*__textinput *__label, *__input],
//  - All parameters have defaults
//  - Parameters:
//    - $color {string} : Default Border color
//    - $color-disabled {string} : Disabled Border color
@mixin form-input-border($color: $form-border-default, $color-disabled: $form-border-disabled, $color-error: $form-error-color) {
  $parentClass: &;

  border: 0 solid transparent;
  border-radius: 0;

  &__textinput,
  &__textarea,
  &__input {
    border: 0 solid transparent;
    border-bottom: 0.0625rem solid $color;
    outline: none;

    //set :hover, :focus, :active, .error, :disabled in this order
    &:focus,
    &.focus,
    &:active,
    &.active {
      border-bottom-width: 0.125rem;
    }

    &.error,
    #{$parentClass}.error & {
      border-bottom-color: $color-error;
    }

    &:disabled,
    &.disabled,
    #{$parentClass}.disabled & {
      border: 0.0625rem solid $color-disabled;
    }
  }
}

// Form Colors: [*__textinput *__label *__input],
//  - All parameters have defaults
//  - Parameters:
//    - $color {string} : Text color
//    - $color-bg {string} : Background color (Default/Primary)
//    - $color-bg-alt {string} : Background color (Alternative)
//    - $color-error {string} : Error label/msg/icon color
@mixin form-input-color(
  $color: $form-color-default,
  $color-bg: $form-bg-default,
  $color-bg-alt: $form-bg-alternative,
  $color-error: $form-error-color
) {
  $parentClass: &;

  background-color: transparent;

  // set background and font color
  &__textinput,
  &__textarea,
  &__input {
    cursor: pointer;

    //set :hover, :focus, :active, .error, :disabled in this order
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      &,
      &.error,
      #{$parentClass}.error & {
        background-color: $color-bg-alt;
      }
    }

    &,
    &.error,
    &:disabled,
    &.disabled,
    #{$parentClass}.error &,
    #{$parentClass}.disabled & {
      background-color: $color-bg;
      color: $color;
    }

    &:disabled,
    &.disabled,
    #{$parentClass}.disabled & {
      opacity: 0.6;
      cursor: not-allowed;
      ~ #{$parentClass}__label {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }

  &__label {
    background-color: transparent;
    color: $color;
    #{$parentClass}:disabled &,
    #{$parentClass}.disabled & {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  &__errmsg {
    color: $color-error;
  }
}

// Label required style
// use by: .textinput-group__label
//         .authentication-input__label
@mixin form-input-label-required() {
  &.asterisk,
  &:required {
    &::after {
      content: '(required)';
      padding-left: 0.4rem;
    }
  }
}

/* INPUT TEXT ---css start here----------*/
/* Note: this style is also used by the dropdown component*/

/*--for wrapper element only--*/
.textinput-type__type {
  width: 2.813rem;
  height: 2.813rem;
  margin-right: 1.375rem;
  font-size: 0.625rem;
  color: $color-ey-confident-grey;
  text-align: right;
}

/*--main group of the component (DIV)*/
.textinput-group {
  @include form-input-border;
  @include form-input-color;

  ///dark theme ---------
  .dark & {
    @include form-input-border($color: $darkform-border-default, $color-disabled: $darkform-border-disabled);
    @include form-input-color(
      $color: $darkform-color-default,
      $color-bg: $darkform-bg-default,
      $color-bg-alt: $darkform-bg-alternative,
      $color-error: $darkform-error-color
    );
  }

  width: 100%;
  position: relative;
  z-index: 0;
}

/*-- component's input box  */
.textinput-group__textinput {
  @include fonts-col__form-field-inputs;
  font-size: 0.875rem !important;
  height: 2.813rem;
  width: 100%;
  text-align: left;
  padding-left: 0.75rem;
  padding-top: 0.75rem;
}

select.textinput-group__textinput {
  padding: 0 0.75rem;
}

/*-- component's label  */
.textinput-group__label {
  @include fonts-col__form-field-input-labels($font-size-4);
  @include form-input-label-required;

  position: absolute;
  left: 0.75rem;
  top: 0.75rem;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  margin-bottom: 0;
}

.textinput-group__label.focus {
  @include fonts-col__field-labels-on-focus-light;
  font-size: $font-size-3 !important; //override
  height: 0.625rem;
  top: 0;
}

/*-- component's error message  */
.textinput-group__errmsg {
  @include fonts-col__field-labels-on-focus;
  font-size: $font-size-3 !important; //override
  padding-left: 0.75rem;
  width: 100%;
  text-align: left;
  position: relative;
  top: 0;
  &:before {
    @include material-icons(1rem);
    content: 'error';
    color: inherit;
    position: absolute;
    margin-left: calc(100% - 2rem);
    top: -2.2rem;
  }
}

/* INPUT FILE */
.textinput-group > .textinput-group__textinput[type='file'] {
  display: none;
}

.textinput-group--full {
  height: 2.813rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: $font-size-4;
  font-family: $font-family-light !important;
  color: $color-ey-confident-grey;
  .textinput-group__textinput {
    padding-top: 0;
  }

  .textinput-group__errmsg {
    padding: 0;
    width: auto;
    position: static;
    margin: 0;
    font-size: $font-size-3;
    font-family: $font-family-light !important;
    color: $color-ey-confident-grey;
    margin-right: $spacing-02;
    @media only screen and (max-width: $break-point-medium) {
      position: absolute;
      left: 0;
      bottom: -1.25rem;
      top: auto;
    }
    &::before {
      content: '';
    }
  }
  .textinput-group__textinput--error {
    outline: $color-red-06 solid 0.0625rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    padding: 0 0 0 3.4375rem;
  }
  .textinput-group__icon {
    position: absolute;
    left: $spacing-05;
    top: 25%;
  }
  .textinput-group__btn {
    border: none;
    font-size: $font-size-4;
    font-family: $font-family-bold !important;
    font-size: $font-size-4;
    margin-right: $spacing-03;
    background: transparent;
  }
}

.btn.btn--primary.textinput-group__button {
  outline: $color-ey-yellow solid 0.0625rem;
  outline-offset: 0;
  border-radius: 0;
  height: 2.813rem;
  width: 8.875rem;
  margin: 0 0 0 0.04rem;
  border-width: 0;
  &:hover,
  &:focus {
    outline: $color-ey-confident-grey solid 0.0625rem;
  }
}

/* INPUT PASSWORD */
.textinput-group__textinput {
  &[type='password'] {
    //override, EYFonts not showing standard password masking symbol
    font-family: 'Noto Sans', Arial, sans-serif !important;
    font-size: 1rem !important;
  }
}

// for format: <i></i> (old format, compatibility)
.textinput-group__togglepwdtext {
  color: #585861; //no token
  position: absolute;
  z-index: 999;
  font-size: $font-size-7;
  transform: translate(calc(-100% + -0.625rem), 0.925rem);
  cursor: pointer;

  &.show:before {
    content: '';
    width: 0.125rem;
    height: 100%;
    border-left: 0.0625rem solid #585861; //no token
    border-right: 0.0625rem solid $color-ey-grey-4;
    position: absolute;
    transform: rotate(-45deg) translate(0.4rem, 0.4rem);
  }

  & ~ .textinput-group__errmsg {
    &:before {
      transform: translate(-1.7rem, 0.1rem);
    }
  }
}
// for format: <button><i></i></button> (accessibility-tabstop)
button.textinput-group__togglepwdtext {
  top: 0;
  right: 0;
  border: none;
  background-color: transparent;
  transform: translate(-0.1875rem, 0.4375rem);
  position: absolute;
  height: 2rem;
  width: 2rem;
  padding: 0;
  line-height: 0;
  .material-icons {
    font-size: inherit;
  }
  &.show:before {
    height: 65%;
    // transform: rotate(-45deg) translate(0.2rem, 0.5rem);
  }
}

// -------------- Text Area Input --------------------
$textarea_height: 6.125rem;
$textarea_labelheight: 1.875rem;

// Form Colors for TextArea: [*__textinput *__label],
//  - All parameters have defaults
//  - Parameters:
//    - $color {string} : Text color
//    - $color-bg {string} : Background color (Default/Primary)
//    - $color-bg-alt {string} : Background color (Alternative)
//    - $color-error {string} : Error label/msg/icon color
@mixin form-textarea-color(
  $color: $form-color-default,
  $color-bg: $form-bg-default,
  $color-bg-alt: $form-bg-alternative,
  $color-error: $form-error-color
) {
  $parentClass: &;

  // set background and font color
  //set :hover, :focus, :active, .error, :disabled in this order
  &:hover,
  &.hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    &,
    &.error {
      background-color: $color-bg-alt;
    }
  }

  &,
  &.error,
  &:disabled,
  &.disabled {
    background-color: $color-bg;
    #{$parentClass}__textarea,
    #{$parentClass}__label {
      color: $color;
    }
  }

  &__textarea,
  &__label {
    background-color: transparent;
    cursor: pointer;
    #{$parentClass}:disabled &,
    #{$parentClass}.disabled & {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  &__errmsg {
    color: $color-error;
  }
}

// Form Borders for TextArea: [*__textarea *__label],
//  - All parameters have defaults
//  - Parameters:
//    - $color {string} : Default Border color
//    - $color-disabled {string} : Disabled Border color
@mixin form-textarea-border($color: $form-border-default, $color-disabled: $form-border-disabled, $color-error: $form-error-color) {
  & {
    $parentClass: &;
    border: 0 solid transparent;
    border-bottom: 0.0625rem solid $color;
    outline: none;

    //set :hover, :focus, :active, .error, :disabled in this order
    &:focus,
    &.focus,
    &:active,
    &.active {
      border-bottom-width: 0.125rem;
    }

    &.error,
    #{$parentClass}.error & {
      border-bottom-color: $color-error;
    }

    &:disabled,
    &.disabled,
    #{$parentClass}.disabled & {
      border: 0.0625rem solid $color-disabled;
    }
  }

  &__textinput,
  &__textarea {
    border: 0 solid transparent;
    border-radius: 0;
    &:focus,
    &.focus,
    &:active,
    &.active {
      outline: none;
    }
  }
}

.textarea-group {
  @include form-textarea-border;
  @include form-textarea-color;

  // Dark Theme ------
  .dark & {
    @include form-textarea-color(
      $color: $darkform-color-default,
      $color-bg: $darkform-bg-default,
      $color-bg-alt: $darkform-bg-alternative,
      $color-error: $darkform-error-color
    );
    @include form-textarea-border($color: $darkform-border-default, $color-disabled: $darkform-border-disabled);
  }

  display: flex !important;
  flex-direction: column !important;
  width: 21.75rem;
  height: $textarea_height;
  z-index: 0;

  /*-- component's label  */
  .textarea-group__label {
    display: flex !important;
    @include fonts-col__form-field-input-labels;
    line-height: 0.75rem !important; //override
    width: 100%;
    text-align: left;
    position: static;
    padding-left: 0.75rem;
    vertical-align: top;
    margin: 0;

    //dynamic based on element state
    padding-top: 1rem;
    height: $textarea_labelheight;
    -webkit-transition: padding-top 0.15s;
    transition: padding-top 0.15s;
  }
  .textarea-group__errmsg {
    @include fonts-col__field-labels-on-focus;
    font-size: 0.75rem !important; //override
    font-weight: normal;
    padding-left: 0.75rem;
    width: 100%;
    text-align: left;
    position: relative;
    top: -1.8rem;
    display: none;
  }
}
/*-- component's textarea box  */
.textarea-group__textarea {
  display: flex !important;
  @include fonts-col__form-field-inputs;
  font-size: 0.875rem !important; //override
  font-weight: 300;
  width: 100%;
  text-align: left;
  padding-left: 0.75rem;
  padding-top: 0.625rem;
  margin-top: 0;
  overflow: hidden;
  resize: none;

  //dynamic based on element state
  height: calc(#{$textarea_height} - #{$textarea_labelheight});
}

.textarea-group.focus,
.textarea-group[focus],
text-area-input.focus .textarea-group,
text-area-input[focus] .textarea-group {
  $textarea_height: 6.125rem;
  $textarea_labelheight: 1.125rem;
  .textarea-group__textarea {
    height: calc(#{$textarea_height} - #{$textarea_labelheight});
  }
  .textarea-group__label {
    padding-top: 0.25rem;
    height: $textarea_labelheight;
  }
}

.textarea-group.active,
.textarea-group[active],
text-area-input.active .textarea-group,
text-area-input[active] .textarea-group {
  $textarea_height: 6.125rem;
  $textarea_labelheight: 1.125rem;
  .textarea-group__textarea {
    height: calc(#{$textarea_height} - #{$textarea_labelheight});
  }
  .textarea-group__label {
    padding-top: 0.25rem;
    height: $textarea_labelheight;
  }
}

.textarea-group.error,
.textarea-group[error],
text-area-input.error .textarea-group,
text-area-input[error] .textarea-group {
  margin-bottom: 1.125rem;
  .textarea-group__errmsg {
    display: inline-block;
    position: static;
    transform: translateY(1.875rem);
    &:before {
      @include material-icons(1rem);
      content: 'error';
      color: inherit;
      position: absolute;
      margin-left: calc(100% - 2rem);
      top: -3.8rem;
    }
  }
}
// END OF-------- Text Area Input --------------------

// -------------- Authentication Numeric Input --------------------

.authentication-input {
  @include form-input-border;
  @include form-input-color;

  // Insert dark theme style here
  ///dark theme ---------
  // .dark & {
  //   @include form-input-border(......);
  //   @include form-input-color(.......);
  // }

  &__input {
    -webkit-appearance: textfield;
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield;

    padding: 0.5rem;
    text-align: center;
    width: 2.813rem;
    height: 2.813rem;
    font-family: $font-family !important;
    font-size: 0.75rem;
    font-weight: 300;
    margin-right: 0.625rem;

    &:hover,
    &.hover,
    &:focus,
    &.focus {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
  }

  &__label {
    @include fonts-col__form-field-input-labels($font-size: $font-size-3);
    line-height: 0.75rem !important; //override
    font-weight: 300;
    height: 0.75rem;
    text-align: left;
  }

  &__errmsg {
    @include fonts-col__field-labels-on-focus($line-height: 0.75rem);
    font-size: 0.75rem !important; //override
    font-weight: 300;
    width: 100%;
    text-align: left;
    padding-top: 0.3125rem;
    display: none;
    .error > & {
      display: block;
      &:after {
        @include material-icons(1rem);
        content: 'error';
        color: inherit;
        position: relative;
        left: calc(50% + 0.53rem);
        top: -2.2rem;
      }
    }
  }
}
// END OF-------- Authentication Numeric Input --------------------

// --------------
.richtext-editor {
  border: 0.0625rem solid $color-ey-grey-2;
  border-radius: 0;

  .navbar {
    justify-content: flex-start;
    background: $color-ey-grey-4;
    border-radius: 0;
    padding: 0.125rem 0.375rem !important;
    border-bottom: 0.0625rem solid $color-border-2;

    .navbar__menu {
      display: flex;
      padding: 0 0.25rem;
      .navbar_link {
        padding: 0.25rem 0.46875rem 0 0.46875rem;
        margin: 0;
      }
    }

    & .navbar__pipe {
      width: 0.0625rem;
      height: 1.625rem;
      border-left: 0.0625rem solid $color-border-2;
      margin-top: 0.4375rem;
      margin-left: 0.25rem;
    }
  }
  .material-icons {
    font-size: 1.25rem;
    width: 1.5rem;
    height: 1.25rem;
  }
  &__textarea {
    height: 7.625rem;
    color: $color-ey-confident-grey;
    font-size: 0.875rem;
    line-height: 1.375rem;
    margin: 0.8125rem 0.9375rem;
    border: none;
    resize: none;
  }
}

@media (max-width: 1500px) and (min-width: 576px) {
  .richtext-editor {
    .navbar .navbar__menu {
      display: flex !important;
    }
  }
}

/* RADIO BUTTON */

/* The container */

.rbcontainer {
  $self: &;
  text-align: left;
  $max-width: 48rem;
  &__wrapper {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    p {
      padding-right: 1rem !important;
    }
    @media (max-width: $max-width) {
      display: flex;
      align-items: center;
      margin: 0 0 1.5625rem 0;

      p {
        margin: 0 1.25rem 0 0;
        padding: 0 !important;
        width: 100%;
        max-width: 6.25rem;
      }
    }
  }
  &__label {
    @include fonts-col__instructional-text;
    position: relative;
    padding-left: 1.875rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-right: 1.25rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: top;
    text-align: left;
    display: inline-block;
    line-height: 1.3125rem;
    & strong {
      @include fonts-col__table-data-headers;
    }
    &.focus {
      outline: 0.0625rem solid #aaaab1;
      outline-offset: 0.375rem;
    }
  }
  &__label.error {
    /* Create a custom radio button with error*/
    color: $color-ey-red;
  }

  &__checkmark {
    /* Create a custom radio button */
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    background-color: $color-white;
    border-radius: 50%;
    border: 0.0625rem solid $color-ey-confident-grey;
    vertical-align: 50%;
    @media (max-width: $max-width) {
      top: calc(50% - 0.625rem);
    }
  }
  /* Style the indicator (dot/circle) */
  &__checkmark:after {
    top: 0.125rem;
    left: 0.125rem;
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    background: $color-ey-confident-grey;
  }
  /* When the radio button is checked, add a blue background */
  input:checked ~ &__checkmark {
    background-color: $color-white;
    border: 0.0625rem solid $color-ey-confident-grey;
  }

  /* When the radio button is disabled, set the border */
  input:disabled ~ &__checkmark {
    background-color: $color-white;
    border: 0.0625rem solid $color-ey-grey-2;
    cursor: not-allowed;
  }

  input:disabled ~ &__checkmark:after {
    background-color: $color-ey-grey-2;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  &__checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ &__checkmark:after {
    display: block;
  }

  /* Hide the browser's default radio button */
  &__label {
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  }

  /* On mouse-over, add a white background color */
  &__label:hover {
    input ~ #{ $self }__checkmark {
      background-color: $color-white;
    }
  }

  &__label-disabled {
    color: $color-ey-grey-2;
  }

  &__label-disabled:hover {
    cursor: not-allowed;
  }

  &__label.disabled {
    cursor: not-allowed;
  }

  /* hide the disabled indicator */
  &__label.rbcontainer__label.disabled {
    #{ $self }__checkmark:after {
      display: none;
    }
  }

  /* Style the error indicator*/
  &__error-container {
    padding: 0.125rem;
    margin: 0;
    text-align: left;
    display: flex;
    align-items: center;
  }

  &__container-icon {
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: $color-ey-red;
    text-align: left;
    padding-right: 0.5rem;
    display: inline-block;
    vertical-align: top;
    margin-left: 1.75rem;
  }

  &__error-container-message {
    color: $color-ey-grey-1;
    line-height: 0.9375rem;
    vertical-align: top;
    margin-top: -0.125rem;
    display: inline-block;
    font-size: 0.75rem;
  }

  &__header {
    display: block;
    color: $color-ey-gray-alt;
    line-height: 1.3125rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  &--vertical {
    display: block;
    margin-bottom: 0.25rem;
  }
}

/* ON / OFF */

.sets {
  margin-bottom: 5rem;
  margin-top: 5rem;
}

/* The switch - the box around the slider */

.switch {
  position: relative;
  display: inline-block;
  width: 2.188rem;
  height: 0.875rem;
}

/* The slider */

.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0.0625rem solid transparent; //required to avoid misaligment
  background-color: $color-ey-grey-2;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  &:before {
    position: absolute;
    content: '';
    height: 1.25rem;
    width: 1.25rem;
    left: -0.125rem;
    bottom: -0.25rem;
    background-color: $color-ey-grey-1;
    box-shadow: 0.0625rem 0 0.125rem 0 rgba(0, 0, 0, 0.25);
    -webkit-transition: 0.3s;
    transition: 0.3s;

    :focus:not(:disabled) + &,
    :hover:not(:disabled) + &,
    :active:not(:disabled) + & {
      border: 0.0625rem solid $form-color-default;
    }
  }

  /* Rounded sliders */
  .switch--round & {
    border-radius: 2.125rem;
    &:before {
      border-radius: 50%;
    }
  }
}

.switch__input {
  opacity: 0; //Hide default HTML checkbox
  width: 0;
  height: 0;

  /*-- toggle is ON --*/
  &:checked {
    & + .switch__slider {
      background: $color-green-03;
      &:before {
        background: $color-green-05;
        box-shadow: -0.0625rem 0 0.0625rem 0 rgba(0, 0, 0, 0.25);
        -webkit-transform: translateX(1rem);
        -ms-transform: translateX(1rem);
        transform: translateX(1rem);
      }
    }
  }
  /*--END OF: toggle is is on --*/

  /*-- toggle is DISABLED --*/
  &:disabled {
    & + .switch__slider {
      background-color: transparent;
      border: 0.0625rem solid $color-ey-grey-2;
      cursor: not-allowed;
      &:before {
        background-color: $color-ey-grey-2;
      }
    }
  }
  /*--END OF: toggle is DISABLED --*/
}

.switch-label {
  color: $form-color-default;
  font-family: $font-family-light !important;
  font-size: 0.75rem;
  position: relative;
  display: inline;
  top: -0.425rem;
  left: 1.85rem;

  :checked ~ & {
    font-family: $font-family-bold !important;
  }

  &:before {
    position: absolute;
    content: 'Off';
    left: -3.825rem;
    bottom: -0.1rem;
    font-family: $font-family-bold !important;

    :checked ~ & {
      font-family: $font-family-light !important;
    }
  }
}

.icons-render {
  & .material-icons,
  & .material-icons-outlined,
  & .material-icons-two-tone {
    padding: 0.3rem;
  }

  & .iError {
    color: $color-error;
  }

  & .iWarning {
    color: $color-warning;
  }

  & .light {
    color: $color-white;
  }

  & .tool-tip-label {
    color: $color-black;
    margin: 0%;
    &:before {
      max-width: none;
    }
  }

  & label {
    padding: 0.2rem;
  }

  & .searchbar {
    background: $color-ey-grey-4;
    border-radius: 0;
    border: 0.0625rem solid $color-ey-grey-3;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 3.438rem;
  }

  & .searchbar__content {
    min-height: 2.813rem;
  }

  & .searchbar__form {
    width: 100%;
    min-height: inherit;
    text-align: justify;
    display: -webkit-box;
    display: flex;
  }
  & .searchbar__input {
    width: 93%;
    min-width: 4.375rem;
    min-height: inherit;
    background-color: $color-ey-grey-4;
    font-family: $font-family-light !important;
    font-size: $font-size-4;
    line-height: 1.125rem;
    color: $color-ey-confident-grey;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0;
    padding-left: 1.25rem; //override

    /* clears the 'X' from Chrome */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
      display: none;
    }

    /* clears the 'X' from Internet Explorer */
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
  }

  & .searchbar__input:focus,
  & .searchbar__input:hover,
  & .searchbar__btn:focus,
  & .searchbar__btn:hover {
    box-shadow: none;
  }

  & .searchbar__button {
    min-width: 0%;
    background-color: $color-ey-grey-4;
    height: inherit;
    padding: 0;
    margin: 0;
    width: 10%;
  }

  & .searchbar__button:before {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    font-family: 'Material Icons';
    line-height: 1rem;
    font-size: $font-size-8;
    content: attr(data-content);
    color: $color-ey-confident-grey;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-feature-settings: 'liga';
    font-feature-settings: 'liga';
  }
}

/* Mixin Components */
/* card */
.accordion__card {
  border: none;
}

/* header */
.accordion__cardheader {
  border: none;
  height: 2.813rem;
  padding: 0;
  margin: 0;
}

/* title */
.accordion__title {
  font-size: $font-size-4 !important;
  font-family: $font-family !important;
  line-height: 1.375rem !important;
  margin: auto 0 auto 0;
  color: $color-ey-confident-grey;
  vertical-align: middle;
  padding-left: 0.625rem;
}

/* button */
.accordion__btn {
  width: 100%;
  height: 2.813rem;
  border: 0.0625rem solid $color-ey-grey-2a;
  color: $color-ey-confident-grey;
  text-decoration: none;
  padding-left: 0.625rem;
  padding-right: 1rem;
  margin: 0;
  display: flex;
  background: $color-white;
}

.accordion__btn {
  &:hover,
  &:focus,
  &:active {
    background-color: inherit;
    color: inherit;
    text-decoration: none;
    outline: none;
    // removed: #164999, #158849
    // & .material-icons {
    //   background-color: $color-ey-grey-2a;
    // }
  }
}
.accordion__btn:not(.collapsed) {
  background: $color-ey-grey-5;
  border-bottom: none;
}

/* button - toggle add/minus icon */
.accordion__btn .material-icons {
  font-size: 1.125rem;
  margin: auto 0 auto 0;
}
.accordion__btn.collapsed .material-icons.add {
  display: inherit;
}
.accordion__btn:not(.collapsed) .material-icons.add {
  display: none;
}
.accordion__btn.collapsed .material-icons.minus {
  display: none;
}
.accordion__btn:not(.collapsed) .material-icons.minus {
  display: inherit;
}

/* card body*/
.accordion__textbox {
  border: none;
}
.accordion__cardbody {
  @include fonts-col__sub-nav-default-state;
  color: $color-ey-confident-grey;
  line-height: 1.375rem;
  border: 0.0625rem solid $color-ey-grey-2a; // $color-ey-grey-2;
  border-top: none;
  text-align: justify;
  padding: 0.75rem 1.25rem 0.75rem 2.4rem;
  background-color: $color-ey-grey-5;
}

/*----- label only - not part of the component  -----*/
$max-width: 1200px;
.card__wrapper__label {
  width: 7rem;
  margin: 0.1875rem 0.625rem 0;
  text-align: right;
  font-size: $font-size-3;
  color: $color-ey-confident-grey;
  @media (max-width: $max-width) {
    display: block;
    margin: 0.5rem 0;
    text-align: center;
    width: 100%;
  }
}

.card__wrapper {
  display: flex;
  justify-content: center;
  margin: 0 0 1.25rem 0;
  @media (max-width: $max-width) {
    display: block;
    margin: 0 auto;
  }
}
/*----- Activity Card - Main/Actual component -----*/

.activity-card {
  display: -webkit-box;
  display: flex;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  background-color: $color-white;
  max-width: 300px;
  height: 75px;
  width: 100%;
  padding: 0.5rem;
}
.activity-card-panel__header {
  @include fonts-col__main-nav-default-state;
}
.activity-card__body {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  color: $color-ey-confident-grey;
  text-align: left;
  padding: 0 0 0 0.25rem;
  height: 67px;
  overflow-y: auto;
}

.activity-card__textline1 {
  @include fonts-col__instructional-text;
  & strong {
    @include fonts-col__table-data-headers(1.125rem);
  }
}

.activity-card__textline2 {
  @include fonts-col__field-labels-on-focus(0.9375rem);
  margin-top: 0.1875rem;
  color: $color-ey-grey-1;
}

.activity-card__close {
  font-size: 1rem;
  color: $color-ey-confident-grey;
  cursor: pointer;
  text-align: left;
  margin: 0.1875rem 0.5rem 0 0.5rem;
}

.activity-card__cancel {
  margin-top: auto;
  font-size: 0.75rem;
  color: $color-ey-link-blue;
  line-height: 1.125rem;
  padding: 0.4rem 0.7rem;
  &:hover {
    color: $color-ey-link-blue;
    text-decoration: none;
  }
}

.activity-card__icon {
  font-size: 1rem;
  color: $color-ey-gray-tint-1;
  margin-top: 2px;

  .activity-card--warning & {
    //color: #ff6d01; /*color token not found*/
    color: $color-orange-04; /*using alternative*/
  }
  .activity-card--success & {
    //color: #32964f; /*color token not found*/
    color: $color-green-03; /*using alternative*/
  }
  .activity-card--info & {
    //color: #145cb4; /*color token not found*/
    color: $color-blue-03; /*using alternative*/
  }
  .activity-card--error & {
    color: $color-validation-error;
  }
  .activity-card--action & {
    //color: #145cb4; /*color token not found*/
    color: $color-blue-03; /*using alternative*/
    align-self: flex-start;
    -webkit-animation: do360 5s infinite; /* Chrome, Safari, Opera */
    animation: do360 5s infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
}
/*For Icon animation -- Chrome, Safari, Opera */
@-webkit-keyframes do360 {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
/*For Icon animation -- Standard syntax */
@keyframes do360 {
  100% {
    transform: rotate(360deg);
  }
}
.activity-card-panel {
  max-width: 310px;
  min-width: 280px;
  width: 100%;
  background-color: $color-white;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0.0625rem solid $color-ey-gray-border-1;
    border-left: 0.0625rem solid $color-ey-gray-border-1;
    border-right: 0.0625rem solid $color-ey-gray-border-1;
    font-size: 1rem;
    max-height: 45px;
    color: $color-ey-confident-grey;
    padding: 0.8125rem;
    text-align: left;

    &--menu {
      border-radius: 50%;
      height: 1.7rem;
      padding: 0;
      text-align: center;
      width: 1.7rem;
      color: $color-ey-grey-2;

      &:hover,
      &:focus,
      &:active {
        //color: #212529; /*color token not found*/
        color: $color-ey-confident-grey; /*using alternative*/
        background-color: transparent !important;
      }
    }
  }
  &__dropdown {
    border-radius: 0;
    cursor: pointer;
  }
  &__body {
    border-bottom: 0.0625rem solid $color-ey-gray-border-1;
    border-left: 0.0625rem solid $color-ey-gray-border-1;
    border-right: 0.0625rem solid $color-ey-gray-border-1;
    overflow-y: auto;
    max-height: 600px;
  }
  &__collapsible-header {
    color: $color-ey-confident-grey;
    background-color: $color-ey-grey-4;
    padding: 0.5625rem 0.625rem 0.5625rem 1.4375rem;
    text-align: left;
    font-size: $font-size-3;
    cursor: pointer;

    &::after {
      font-family: 'Material Icons';
      content: 'keyboard_arrow_down';
      font-size: $font-size-4;
      float: right;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
    }

    &.collapsed::after {
      content: 'keyboard_arrow_up';
    }
  }
  &__collapsible-body {
    & > div {
      border-bottom: 0.0625rem solid $color-ey-gray-border-1;
    }
  }
  .activity-card {
    max-width: 310px;
  }
}

.color01 {
  background-color: $color-ey-interaction-overlays;
}
.color02 {
  background-color: $color-ey-confident-grey;
}
.color03 {
  background-color: $color-ey-grey-1;
}
.color04 {
  background-color: $color-ey-grey-2;
}
.color05 {
  background-color: $color-ey-grey-3;
}
.color06 {
  background-color: $color-ey-grey-4;
}
.color07 {
  background-color: $color-white;
  border: 1px solid $color-ey-grey-2;
}
.color08 {
  background-color: $color-ey-yellow;
}
// Maroons
.color09 {
  background-color: $color-maroon-01;
}
.color10 {
  background-color: $color-maroon-02;
}
.color11 {
  background-color: $color-maroon-03;
}
.color12 {
  background-color: $color-maroon-04;
}
.color13 {
  background-color: $color-maroon-05;
}
.color14 {
  background-color: $color-maroon-06;
}
.color15 {
  background-color: $color-maroon-07;
}
// Reds
.color16 {
  background-color: $color-red-01;
}
.color17 {
  background-color: $color-red-02;
}
.color18 {
  background-color: $color-red-03;
}
.color19 {
  background-color: $color-red-04;
}
.color20 {
  background-color: $color-red-05;
}
.color21 {
  background-color: $color-red-06;
}
.color22 {
  background-color: $color-red-07;
}
// Oranges
.color23 {
  background-color: $color-orange-01;
}
.color24 {
  background-color: $color-orange-02;
}
.color25 {
  background-color: $color-orange-03;
}
.color26 {
  background-color: $color-orange-04;
}
.color27 {
  background-color: $color-orange-05;
}
.color28 {
  background-color: $color-orange-06;
}
.color29 {
  background-color: $color-orange-07;
}
// Greens
.color30 {
  background-color: $color-green-01;
}
.color31 {
  background-color: $color-green-02;
}
.color32 {
  background-color: $color-green-03;
}
.color33 {
  background-color: $color-green-04;
}
.color34 {
  background-color: $color-green-05;
}
.color35 {
  background-color: $color-green-06;
}
.color36 {
  background-color: $color-green-07;
}
// Teal
.color37 {
  background-color: $color-teal-01;
}
.color38 {
  background-color: $color-teal-02;
}
.color39 {
  background-color: $color-teal-03;
}
.color40 {
  background-color: $color-teal-04;
}
.color41 {
  background-color: $color-teal-05;
}
.color42 {
  background-color: $color-teal-06;
}
.color43 {
  background-color: $color-teal-07;
}
// Blue
.color44 {
  background-color: $color-blue-01;
}
.color45 {
  background-color: $color-blue-02;
}
.color46 {
  background-color: $color-blue-03;
}
.color47 {
  background-color: $color-blue-04;
}
.color48 {
  background-color: $color-blue-05;
}
.color49 {
  background-color: $color-blue-06;
}
.color50 {
  background-color: $color-blue-07;
}
// Purple
.color51 {
  background-color: $color-purple-01;
}
.color52 {
  background-color: $color-purple-02;
}
.color53 {
  background-color: $color-purple-03;
}
.color54 {
  background-color: $color-purple-04;
}
.color55 {
  background-color: $color-purple-05;
}
.color56 {
  background-color: $color-purple-06;
}
.color57 {
  background-color: $color-purple-07;
}
// States
.color58 {
  background-color: $color-ey-confident-grey;
}
.color59 {
  background-color: $color-prompt-label-text;
}
.color60 {
  background-color: $color-inactive-icon-text;
}
.color61 {
  background-color: $color-error;
}
.color62 {
  background-color: $color-warning;
}
.color63 {
  background-color: $color-success;
}
.color64 {
  background-color: $color-ey-link;
}
.color65 {
  background-color: $color-ey-grey-2a;
}
.color66 {
  background-color: $color-ey-grey-5;
}

.avatar {
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border: solid 0.125rem $color-white;
  color: $color-white;
  text-align: center;
  text-transform: uppercase;
  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
  &::after {
    text-transform: none;
    position: absolute;
    top: -0.125rem;
    right: -0.5rem;
    background-color: $color-white;
    border-radius: 50%;
    display: block;
    width: 0.8125rem;
    height: 0.8125rem;
    line-height: 0.8125rem;
    font-family: 'Material Icons';
    font-size: $font-size-4;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
  }
  &--description {
    display: block;
    color: $color-white;
    font-size: 0.875rem;
    font-family: $font-family-light !important;
  }
  &--image {
    box-sizing: content-box;
  }
  &--small {
    width: 1.875rem;
    height: 1.875rem;
    margin: 0.4375rem 0.625rem 0.4375rem 0;
    @include fonts-col__body-copy(1.5625rem);
  }
  &--medium {
    width: 2.4375rem;
    height: 2.4375rem;
    margin: 1.1875rem 0.625rem 0.4375rem 0;
    @include fonts-col__body-copy(1.5625rem);
  }
  &--large {
    width: 3.125rem;
    height: 3.125rem;
    margin: 0.4375rem 0.625rem 0.4375rem 0;
    font-family: $font-family-light !important;
    font-size: 1.625rem;
    line-height: 2.625rem;
  }

  &--approved {
    box-shadow: 0 0 0 0.125rem $color-green-06;
    box-sizing: content-box;
    margin-right: 1.5625rem;
    &::after {
      color: $color-green-06;
      content: 'check_circle';
    }
  }

  &--failed {
    box-shadow: 0 0 0 0.125rem $color-red-06;
    margin-right: 1.5625rem;
    box-sizing: content-box;
    &::after {
      color: $color-red-06;
      content: 'cancel';
    }
  }

  &--on-hold {
    box-shadow: 0 0 0 0.125rem $color-orange-02;
    box-sizing: content-box;
    margin-right: 1.5625rem;
    &::after {
      color: $color-orange-02;
      content: 'error';
    }
  }

  &--default {
    box-shadow: 0 0 0 0.125rem rgba($color-ey-grey-2, 0.35);
    box-sizing: content-box;
    margin-right: 1.5625rem;
    &::after {
      display: none;
    }
  }
  &:hover {
    color: $color-white;
    text-decoration: none;
  }
}

.cards {
  background: $color-white;
  border: 1px solid rgba($color-ey-grey-2, 0.1);
  box-shadow: 0.125rem 0.125rem 0.5rem 0rem rgba($color-ey-interaction-overlays, 0.2);
  height: 180px;
  max-width: 320px;
  width: 100%;
  padding: 1.5625rem;
  position: relative;
  overflow: hidden;
  margin: 0 1.25rem 1.25rem 0;
  display: inline-block;
  transform: scale(1);
  transition: all 0.3s;

  &:hover {
    transform: scale(1.03);
  }

  &__header {
    color: $color-blue-06;
    font-family: $font-family-light !important;
    font-size: $font-size-5;
    line-height: 1.3125rem;
    margin: 0 0 0.4375rem 0;
  }

  &__content {
    font-family: $font-family-light !important;
    font-size: $font-size-3;
    line-height: 1.125rem;
    color: $color-ey-confident-grey;
    max-height: 4.375rem;
    overflow-y: auto;
  }
  &__footer {
    background-color: $color-white;
    padding: 0 1.5625rem 1.5625rem 1.5625rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    bottom: 0;
    position: absolute;
    width: 100%;
    left: 0;

    &-icon {
      justify-content: flex-end;
    }
  }
  &__icon {
    widows: 2rem;
    height: 2rem;
    font-size: $font-size-9;
  }
  &__link {
    width: 1.5rem;
    height: 1.5rem;
    font-size: $font-size-5;
    color: $color-ey-confident-grey;
    cursor: pointer;
    margin-left: 3px;
    margin-right: 3px;
    &:hover {
      color: #0056b3 !important;
    }
    i {
      font-size: $font-size-5;
    }
  }
}

/* DATA TABLE */

@mixin fixPositionFirstColumn() {
  //position: fixed; /* added to support older browsers //notworking */
  position: sticky;
  position: -webkit-sticky;
  left: 0;
}

.data-table-standard {
  border: 0.0625rem solid $color-ey-grey-3;
  &-container {
    width: 100%;
  }
  &__row {
    font-size: 0.75rem;
    color: $color-ey-confident-grey;
    text-align: left;
    line-height: 1.125rem;
    height: 2.813rem;
    vertical-align: middle;

    &:first-child {
      border-top: 0.125rem solid $color-border-2;
      &:hover {
        border-top: 0.125rem solid $color-border;
      }
    }
    & .btn:focus {
      outline: 0.3125rem auto -webkit-focus-ring-color;
    }
  }
  &__header-row {
    background-color: $color-white;
    font-family: $font-family-bold;
    font-size: 0.875rem;
    color: $color-ey-confident-grey;
    line-height: 1.313rem;
    height: 2.5rem;
    & :nth-child(n + 2) {
      min-width: 9rem;
    }
    .table-bordered thead & th {
      border-bottom: none; //override, moved border to tbody 1st-tr for the hover to work
    }
  }
  &__filter-button {
    display: none;
  }
  &__filter-button,
  &__edit-button {
    float: right;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  & button.data-table-standard__edit-button {
    padding: 0;
    margin: 0.0625rem 0.375rem;
  }
  & tbody tr {
    background-color: $color-white;
    border-collapse: separate;
    border-spacing: 0.1875rem;
    border: 0.0625rem solid $color-border-2;

    &:nth-of-type(odd) {
      background-color: $color-ey-grey-5;
    }
    &:hover,
    &:focus,
    &:active {
      cursor: pointer;

      td {
        border-top: 0.0625rem solid $color-border;
        border-bottom: 0.0625rem solid $color-border;
        outline: none;
        font-family: $font-family-bold !important;
      }
    }
  }
  & td {
    @include fonts-col__table-data(1.25rem);
    padding: 0 0.5rem 0 0.5rem;
    border: none;
    vertical-align: middle;
    &.data-table-standard__actions {
      max-width: 4.75rem;
      background-color: inherit;
      padding: 0;
      @include fixPositionFirstColumn();
      box-shadow: 0.3125rem 0 0.2rem -0.1125rem $color-border-2;
      & > div {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        padding-right: 0.5625rem;
      }
      & .container {
        margin: 0;
        padding: 0;
        width: 4.125rem;
        cursor: pointer;
        & .checkbox__checkmark {
          left: 0.75rem;
          top: 0.39rem;
          cursor: pointer;
        }
      }
    }

    .btn--link-blue {
      margin: 0;
      height: 1.375rem;
      font-size: 0.75rem !important;
      text-align: left;
    }
  }
  & thead tr {
    border: 0.0625rem solid $color-border-2;
  }
  & th {
    @include fonts-col__table-data-headers(1.25rem);
    padding: 0.5rem;
    border: none;
    text-align: left;
    &.data-table-standard__actions {
      max-width: 4.75rem;
      border: none;
      @include fixPositionFirstColumn();
      &--border {
        background-color: $color-white;
        border-bottom: 0.125rem solid $color-border-2;
        -webkit-box-shadow: 0.3125rem 0 0.2rem -0.1125rem $color-border-2;
        box-shadow: 0.3125rem 0 0.2rem -0.1125rem $color-border-2;
      }
      & label {
        margin: 0;
        padding: 0;
        width: 4.125rem;
        & .checkbox__checkmark {
          position: relative;
          left: 0.25rem;
          top: 0.29rem;
          cursor: pointer;
          min-width: 1.125rem;
          display: block;
        }
      }
    }
    & .data-table-standard__sort-button {
      min-width: 1.125rem !important;
      width: 1.125rem;
      height: 1.5rem;
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0;
      color: $form-color-default;
      float: right;
      display: flex;
      .material-icons {
        font-size: 1.125rem;
        line-height: 1.5rem;
      }
    }
  }

  //Bug#84879: Content is visible to the left of the checkboxes when scrolled.
  & tr {
    td:first-child:after,
    th:first-child:after {
      content: '';
      position: absolute;
      left: -0.0625rem;
      top: 0;
      height: 100%;
      border-left: 0.0625rem solid #e4e4e4; //no token, must be same as border but 100% opacity
      //this is to hide content on left most portion when scrolling the table.
    }
  }
}

/* TOOL BAR */

/*---- Customization for Toolbox start here -------------*/

/* Icons size */

.material-icons {
  &.mi-25rem {
    font-size: 2.5rem;
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 0.3125rem;
  }
  &.mi-20rem {
    font-size: 2rem;
    width: 2rem;
    height: 2rem;
  }
  &.mi-15rem {
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  &.mi-13rem {
    font-size: 1.3rem;
    vertical-align: middle;
    width: 1.3rem;
    height: 1.3rem;
    &--right {
      float: right;
      line-height: 1.5rem;
    }
  }
  &.mi-125rem {
    font-size: 1.25rem;
    height: 1.25rem;
    vertical-align: middle;
  }
  &.mi-11rem {
    font-size: 1.1rem;
    height: 1.1rem;
    vertical-align: middle;
  }
}

.ey-export-icon {
  background-image: url(/assets/images/toolbar/excel-export.svg);
  background-size: contain;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  display: inline-flex;
}

.navbar {
  $this: &;
  padding: 0.375rem !important;
  margin: 0 !important;
  justify-content: space-between;
  & #{$this}__menu {
    padding-left: 0.25rem;
    padding-right: 1.5rem;
    @media (min-width: 1240px) and (max-width: 1500px) {
      display: none !important;
    }
  }
  & #{$this}__link {
    color: $color-black;
    padding: 0.5rem 0.46875rem;
    margin: 0%;
    padding-bottom: 0;
    &:hover,
    &:focus {
      background-color: $color-gray-alt-2;
    }
    &:active {
      background-color: $color-gray-alt-2;
    }
    &__responsive {
      color: $color-ey-confident-grey;
      padding: 0.5rem 0.46875rem;
      margin: 0%;
      padding-bottom: 0;
      &:hover,
      &:focus {
        color: $color-ey-grey-1;
      }
      &:active {
        color: $color-ey-grey-1;
      }
    }
  }
  & #{$this}__dropdown {
    margin: 0%;
    & #{$this}__link {
      min-width: min-content;
      height: 3rem;
      margin: 0rem;
      padding: 0.5rem 0.625rem;
    }
  }
  & #{$this}__dropdown-menu {
    @media (min-width: 1240px) and (max-width: 1500px) {
      display: flex !important;
    }
    & .dropdown-item.aOptionsViews {
      color: $color-ey-link-blue;
    }
    .dropdown-menu.dropright .dropdown-menu.dropDownMenuSavedViews {
      top: calc(100% - 2.813rem);
    }
  }
  & #{$this}__form {
    max-height: 2.813rem;
    &__content {
      height: auto;
      border: 0.03125rem solid rgba($color-ey-grey-2, 0.35);
      background-color: $color-ey-grey-4;
      z-index: 2;
      &.active {
        border-bottom: none;
        box-shadow: 0.125rem 0.125rem 0.25rem 0 rgba($color-black, 0.3);
        border-right: none;
      }
      & #{$this}__form__render-pills {
        text-align: justify;
        min-height: 2.813rem;
        max-width: 85%;
        & > .pill {
          margin-left: 0.4375rem;
          margin-right: 0.4375rem;
          height: 1.563rem;
          &.pill--dropdown {
            display: inline-block;
            padding: 0;
            position: relative;
            z-index: 99;
            top: 0;
            padding-top: 0.0625rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            margin-top: 0.53125rem;
            margin-bottom: 0.53125rem;
            border-radius: 1.5625rem;
            &.count {
              @include fonts-col__table-data-headers(1rem);
              cursor: pointer;
              color: $color-black;
              letter-spacing: 0;
              text-align: right;
              background: none;
              margin-bottom: 0.40625rem;
            }
            &.hidden,
            &.responsive {
              display: none;
            }
            @media (max-width: 992px) {
              &.pill--dropdown {
                display: none;
              }
              &.responsive {
                display: inline-block;
              }
            }
            & .Pill__label {
              line-height: 1.313rem;
              font-size: 0.875rem;
            }
          }
        }
      }
    }
    &__form {
      width: 100%;
      text-align: justify;
      display: flex;
      z-index: 2;
    }
    &__input {
      width: 100%;
      min-width: 4.375rem;
      background-color: $color-ey-grey-4;
      @include fonts-col__sub-nav-default-state;
      color: $color-ey-confident-grey;
      font-style: italic;
      margin: 0.0625rem;
      height: 2.625rem;
    }
    &__button {
      min-width: 0%;
      background-color: $color-ey-grey-4;
      height: inherit;
      padding: 0;
      right: 1.25rem;
      position: absolute;
      z-index: 3;
      margin-top: 0.625rem;
      margin-right: 0.1875rem;
      &:before {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        vertical-align: middle;
        text-align: center;
        font-family: 'Material Icons';
        line-height: 1rem;
        font-size: 1.5rem;
        content: 'search';
        color: $color-ey-confident-grey;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-feature-settings: 'liga';
        font-feature-settings: 'liga';
      }
    }
    &__input:focus,
    &__input:hover,
    &__btn:focus,
    &__btn:hover {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &__toolbar-menu {
      display: none;
      margin-top: 0;
      background: $color-white;
      border-radius: 0;
      border: 0.0625rem solid $color-ey-grey-4;
      box-shadow: 0.125rem 0.125rem 0.25rem 0 $color-ey-grey-1;
      min-height: 7.938rem;
      min-width: 28.25rem;
      border-top: none;
      padding-top: 0;
      z-index: 999;
      width: inherit;
      text-align: justify;
      & .dropdown--multi-select {
        display: inline-block;
        margin-right: 0.625rem;
        margin-top: 0.625rem;

        & .dropdown-toggle {
          height: 1.875rem;
          width: 6.25rem;
          display: block;
          min-height: 1.875rem;
          // border: 0.0625rem solid $color-ey-grey-2; //use default - input fld
          background-color: $color-white;
          &.dropdown-filter .textinput-group__textinput {
            background: rgba($color-ey-grey-2, 0.35);
          }
          & .textinput-group__textinput {
            padding: 0;
            padding-left: 0.3125rem;
            // background-color: transparent; //use default - input fld
            outline: none;
            padding-left: 0.75rem;
          }
          & .textinput-group__label {
            @media (max-width: 550px) {
              left: 0%;
            }
            position: absolute;
            top: 0.25rem;
            min-height: 0;
            margin-bottom: 0;
            justify-content: normal;
          }
        }
        & .dropdown__expand_less,
        & .dropdown__expand_more {
          right: 0.275rem;
          top: 0.15rem;
        }
      }
      &__header {
        padding: 1.063rem 1.063rem 0.625rem 1.063rem;
      }
      &__information {
        @include fonts-col__table-data(1.125rem);
        max-width: 12.81rem;
        display: inline-block;
      }
      &__remove-filters {
        @include fonts-col__table-data;
        color: $color-ey-link;
        float: right;
        display: none;
        &:hover {
          text-decoration: none;
        }
      }
      &__filters {
        padding: 0 1.063rem 0 1.063rem;
      }
      &__footer {
        margin-bottom: 1.063rem;
        padding: 1.063rem;
        display: block;
      }
      &__close {
        @include fonts-col__table-data;
        color: $color-ey-link;
        float: right;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  & #{$this}__buttons {
    & .btn {
      @include fonts-col__sub-nav-selected-state;
      margin: 0.3125rem;
    }
    & .btn--bkwhite {
      background: $color-white;
      border: 0.0625rem solid $color-black;
    }
  }
  & #{$this}__dropdown {
    & .dropdown-toggle {
      &:after {
        margin-left: 0rem;
        vertical-align: super;
      }
      &.carent-none::after {
        content: none !important;
      }
    }
    & .dropdown-menu {
      color: $color-ey-dark-gray;
      &.dropDownMenuSavedViews {
        top: 70%;
        left: 97%;
      }
      & .dropdown-item {
        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
          background-color: $color-ey-grey-4;
        }
        &.pl-4 {
          padding-left: 2rem !important;
        }
        &.aOptionsViews {
          color: $color-ey-link-blue;
        }
      }
      & .dropdown-header {
        font-size: 0.875rem;
        letter-spacing: 0;
        line-height: 1.313rem;
        color: $color-ey-dark-gray;
      }
    }
  }
  .tool-tip-definition:focus:before,
  .tool-tip-definition:focus:after,
  .tool-tip-definition:active:before,
  .tool-tip-definition:active:after {
    visibility: hidden;
    opacity: 0;
  }
}
.dropdown-toggle {
  &.carent-none::after {
    content: none;
  }
}
.dropdown-item {
  @include fonts-col__instructional-text(1.65rem);
  &:hover,
  &:focus,
  &:active {
    color: initial;
    text-decoration: none;
    background-color: $color-ey-grey-4;
  }
}

/*---------------END OF: Customization for Toolbox -------------*/

/* Group By drag bar */
.data-table-group {
  &--empty,
  &--applied {
    background: $color-white;
    border: 0.0625rem solid $color-ey-grey-2;
    width: auto;
  }
  &--empty {
    min-height: 2.688rem;
    &__message {
      @include fonts-col__body-copy(2.375rem);
      display: inline-block;
      height: 100%;
      width: 100%;
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
      font-style: italic;
      text-align: justify;
    }
  }
  &--applied {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    min-height: 3.125rem;
    width: 100%;
    display: inline-block;
    & .pill {
      border-radius: 1rem;
      border: 0.0625rem solid $color-ey-grey-1;
      display: block;
      height: 100%;
      margin: 0.5625rem 0.3125rem 0.625rem 0.3125rem;
      float: left;
      padding: 0;
      padding-left: 0.4375rem;
      &__icon,
      &__close {
        & .material-icons {
          font-size: 0.9375rem;
        }
      }
      &__icon {
        float: left;
      }
      &__text {
        margin-left: 0.3125rem;
        float: left;
        line-height: 1;
      }
      &__close {
        float: right;
        margin-right: 0.625rem;
        border: none;
        background: transparent;
        color: $color-ey-grey-1;
        padding: 0;
        & .material-icons {
          cursor: pointer;
        }
      }
    }
    & .pill--group-by {
      min-width: 6.25rem;
      font-size: 0.875rem;
      padding-top: 0.4375rem;
      padding-bottom: 0.3125rem;
      min-height: 1.875rem;
    }
  }
}

/*---- Customization for Data Table Flex -------------
NOTE: Data Table Flex is using .data-table-standard as its base style -------*/

.data-table-standard--flex {
  .data-table-standard__header-row {
    :nth-child(n + 2) {
      min-width: 9.188rem;
    }
    .data-table-standard__actions {
      + th {
        padding-left: 1.25rem;
      }
    }
  }
  .data-table-standard__actions {
    > div {
      padding-left: 0.5625rem;
    }
    + td {
      padding-left: 1.25rem;
    }
  }
}

/*----END OF: Customization for Data Table Flex -------------*/

/* Overflow Menu */

/*---- Customization for Overflow Menu start here -------------*/

.overflow-menu {
  &-component {
    display: inline-block;
    position: relative;
  }
  &.dropdown-menu {
    margin-top: 0.625rem;
    padding: 0;
    min-width: 12.5rem;
    background: $color-white;
    border: 1px solid $color-ey-confident-grey;
    border-radius: 0px;
    box-shadow: -0.125rem -0.125rem 0.3125rem 0 rgba($color-ey-interaction-overlays, 0.2);
    &:before {
      content: '';
      position: absolute;
      height: 1.25rem;
      width: 1.25rem;
      border-left: solid 0.0625rem $color-ey-confident-grey;
      border-top: solid 0.0625rem $color-ey-confident-grey;
      top: 0%;
      right: 1rem;
      margin-left: -0.625rem;
      margin-top: -0.6875rem;
      transform: rotate(45deg) skew(0deg, 0deg);
      background: $color-white;
      z-index: -1;
    }
  }
  .dropdown-menu {
    //for sub-menus
    background: $color-white;
    border: solid 0.0625rem $color-border;
    border-radius: 0;
    box-shadow: 0.125rem 0 0.375rem 0 rgba(0, 0, 0, 0.15);
    padding: 0;
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
    width: 9.5rem;
    margin-top: -1rem;
  }
  &--horizontal {
    .dropdown-item {
      .material-icons {
        color: $color-ey-confident-grey;
      }
    }
  }
  &--vertical {
    .dropdown-item {
      .material-icons {
        color: $color-gray-alt-1;
      }
    }
  }

  .dropdown-item {
    @include fonts-col__body-copy(1rem);
    color: $color-ey-confident-grey;
    font-weight: 300;
    border-radius: 0;
    height: 2.813rem;
    width: calc(100% - 0.0625rem);
    padding-left: 1.375rem;
    padding-left: 0.875rem;
    padding-right: 0.875rem;

    &:hover,
    &.hover {
      background: $color-ey-grey-2a;
      outline: none;
      cursor: pointer;
    }

    i {
      margin: 0 0.625rem 0 0;
      font-size: $font-size-5;
      vertical-align: middle;
    }
    &--next-icon {
      position: relative;
      right: -0.4375rem;
      float: right;
      color: $color-ey-gray-alt;
      font-size: $font-size-8 !important;
      margin: 0 !important;
    }
  }
}

.overflow-menu-toggle {
  &.dropdown-toggle {
    & .material-icons {
      cursor: pointer;
      width: 3.438rem;
      font-size: $font-size-8;
      height: $font-size-10;
      color: $color-ey-confident-grey;
    }
    &:hover,
    &:active,
    &:focus {
      .material-icons {
        color: $color-ey-grey-1;
      }
    }
    // prettier-ignore
    &[aria-expanded=true] {
      .material-icons {
        color: $color-ey-grey-1;
        font-size: $font-size-8;
      }
    }
    &:after {
      content: none;
    }
  }
  &.overflow-menu-toggle--datatable {
    & .material-icons {
      font-size: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
      margin-top: 0.25rem;
    }
    & + .dropdown-menu {
      margin-left: 0.75rem;
      margin-top: -0.6875rem;
      &:before {
        //left arrow
        top: 2rem;
        right: 9.463rem;
        transform: rotate(136deg) skew(15deg, 15deg);
        border-right: 0.0625rem solid $color-border;
        border-bottom: 0.0625rem solid $color-border;
        border-left: none;
        border-top: none;
        height: 1rem;
        width: 1rem;
      }
    }
  }
}

.footer {
  &__group {
    font-family: $font-family;
    color: $color-white;
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 1.5rem;
    text-align: center;
    background: $color-ey-confident-grey;
    width: 100%;
    vertical-align: middle;
    padding: 0 2rem 0 2rem;
  }
  &__image {
    margin-top: 2.429rem;
    margin-bottom: 0;
    width: 5.143rem;
    height: 5.143rem;
    vertical-align: middle;
    border-style: none;
  }
  &__parag {
    @include fonts-col__sub-nav-default-state;
    line-height: 1.313rem !important;
    padding: 1rem 1em 3.143rem 1em !important;
    margin-bottom: 0;
    & .material-icons {
      font-size: $font-size-5;
      vertical-align: sub;
    }
  }
  &--anchor,
  &--anchor:active,
  &--anchor:hover,
  &--anchor:visited {
    color: $color-ey-yellow;
    font-weight: 500;
    text-decoration: none;
  }

  &--sticky {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__sticky-footer {
    display: flex;
    @media screen and (max-width: $break-point-small) {
      display: block;
    }
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.05);
    min-height: 4.0625rem;
    width: 100%;
    background: $color-white;
    flex-shrink: 0;
    padding: $spacing-01 $spacing-03;
    button {
      margin: 0 1rem 0 0;
      @media screen and (max-width: $break-point-small) {
        margin-bottom: 0.3125rem;
        display: block;
        width: 100%;
      }
    }
    button:last-child {
      margin: 0;
    }
    i {
      margin: 0;
    }
  }

  &__sticky-body {
    flex-grow: 1;
    padding: $spacing-02 $spacing-03;
  }
}

@mixin badge() {
  & {
    color: $color-white;
    background: transparent;
    width: 1rem;
    height: 1rem;
    top: 0.2625rem;
    position: absolute;
    font-size: 0.5rem;
    padding-top: 0.6rem;
    right: 0;
    font-family: $font-family-bold !important;
  }
}
.global-header {
  position: relative;
  background: $color-ey-confident-grey;
  width: 100%;
  grid-area: head;
  justify-content: space-between;
  align-items: center;
  min-height: 3.125rem;
  &__tittle {
    display: flex;
    &__image {
      width: 1.5625rem;
      height: 1.625rem;
      margin: 0.75rem 0.375rem 0.75rem 0.9375rem;
    }
    &__divider {
      width: 0;
      height: 0.625rem;
      margin: 1.625rem 0 0.875rem 0;
      border-left: 0.0625rem solid $color-white;
      display: none; //latest assets
    }
    &__label {
      color: $color-white;
      margin: 1.5625rem 0 0.875rem 0.5rem;
      font-size: 0.9375rem;
      height: 0.6875rem;
      line-height: 0.6875rem;
      font-family: $font-family-light !important;
    }
  }
  &__actions {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    &__button {
      position: relative;
      background: 0 0;
      border: none;
      padding: 0;
      color: $color-white;
      width: 3.125rem;
      height: 3.125rem;
      line-height: 4.125rem;
      &:last-child {
        margin-right: 0.25rem;
      }
      &.material-icons.badgepad {
        padding-right: 0.3125rem; //adjust for badge
      }
      & .badge {
        @include badge();
      }
      &:hover,
      &:focus,
      &:active {
        background-color: $color-ey-interaction-overlays;
        outline: none;
      }
    }
  }
  &__search {
    position: absolute;
    right: 12.4375rem;
    top: 0;
    display: none;
    z-index: 2;
    @media screen and (max-width: $break-point-small) {
      right: 0;
    }
    &__input {
      background: $color-ey-interaction-overlays;
      & input {
        color: $color-white;
        position: relative;
        border-radius: 0;
        border: none;
        height: 3.0625rem;
        width: 24.6875rem;
        background-color: transparent;
        &:focus {
          color: $color-white;
          background-color: transparent;
        }
      }
      & .material-icons {
        position: absolute;
        top: 0.9375rem;
        right: 0.9375rem;
        color: $color-white;
        font-size: 1.25rem;
        cursor: pointer;
      }
    }
    &__content {
      background: $color-ey-confident-grey;
      border-radius: 0;
      box-shadow: 0 0 0.5625rem 0 rgba($color-black, 0.09);
      width: 24.6875rem;
      padding-bottom: 1.25rem;
      &__section {
        &__tittle {
          padding-left: 1.25rem;
          padding-right: 1.25rem;
          & span {
            border-bottom: 0.03125rem solid $color-ey-grey-2;
            padding-top: 1.25rem;
            padding-bottom: 0.375rem;
            font-size: 0.75rem;
            color: $color-white;
            display: inline-block;
            width: 80%;
            text-align: justify;
            cursor: pointer;
            & i {
              line-height: 1.5;
              font-size: $font-size-4;
              margin-right: 0.8125rem;
            }
          }
          & a.view-all {
            width: 20%;
            display: inline-block;
            color: $color-white;
            font-size: 0.75rem;
            border-bottom: 0.03125rem solid $color-ey-grey-2;
            padding-bottom: 0.375rem;
            cursor: pointer;
            &:hover {
              color: $color-white;
            }
          }
        }
        &__options {
          & span {
            display: flex;
            padding-left: 2.875rem;
            padding-top: 0.9375rem;
            font-size: 0.75rem;
            color: $color-white;
            font-family: $font-family-light !important;
          }
        }
      }
    }
    &__menu {
      width: 26.5625rem;
      height: 100%;
      background: $color-ey-confident-grey;
      right: -26.5625rem;
      position: fixed;
      top: 0;
      transition: right 0.5s;
      display: flex;
      z-index: 9999;
      &.hideMobile {
        right: -26.5625rem;
      }
      &.showMobile {
        right: 0%;
      }
      &-left {
        width: 5.6875rem;
        background: $color-ey-interaction-overlays;
        box-shadow: 0.1875rem 0 0.5rem 0 rgba($color-black, 0.15);
        position: relative;
        text-align: center;
        & .avatar {
          border: none;
          margin-right: 0;
        }
        & button.avatar {
          background-color: inherit;
        }
        &__button {
          background: 0 0;
          border: none;
          min-height: 3.6875rem;
          width: 5.6875rem;
          color: $color-white;
          margin-top: 1.375rem;
          &:last-child {
            right: 0;
            position: absolute;
            bottom: 0;
          }
          & .material-icons {
            position: relative;
            & .badge {
              @include badge();
            }
          }
          & span {
            display: block;
            font-size: 0.875rem;
            font-family: $font-family-light !important;
          }
          &.active {
            background: $color-ey-confident-grey;
            border-bottom: 0.25rem solid $color-ey-yellow;
          }
        }
      }
      &-right {
        width: 20.875rem;
        & .btn-close {
          display: block;
          width: 2.25rem;
          height: 2.25rem;
          color: $color-white;
          cursor: pointer;
          margin: 0.9375rem 1.25rem 1.875rem 0;
          padding: 0;
          border: 0;
          background: transparent;
          float: right;
          & .material-icons {
            font-size: 2.25rem;
          }
        }
        & ul {
          list-style-type: none;
          padding: 0;
          & li {
            width: 100%;
            display: flex;
            color: $color-white;
            & button {
              // override default button style
              background-color: inherit;
              min-width: inherit;
            }
            & a,
            & button {
              cursor: pointer;
              text-align: left;
              font-size: $font-size-5;
              font-family: $font-family-light !important;
              padding: 0.5625rem 0 0.5rem 1.875rem;
              width: 100%;
              border: inherit; //override default button style
              border-left: 0.25rem solid transparent;
              color: inherit; //override default button,a style
              height: 2.813rem;
              line-height: 1.75rem;
              &:hover,
              &:focus,
              &.active,
              &:active {
                outline: none;
                text-decoration: none; //override default a:href style
                background: $color-ey-interaction-overlays;
                font-family: $font-family-bold !important;
              }
              &:focus,
              &.active,
              &:active {
                border-left: 0.25rem solid $color-ey-yellow;
              }
            }
          }
        }
      }
    }
  }
  &__content {
    min-height: 1.25rem;
    &__tittle {
      display: flex;
      font-size: $font-size-4;
      color: $color-white;
      line-height: 0.75rem;
      margin-left: 0.9375rem;
      margin-bottom: 0;
      font-family: $font-family-light !important;
      .global-header--tabs & {
        margin-bottom: 1rem;
      }
    }
  }
  &--tabs__tabs {
    margin-top: 1rem;
    & ul {
      list-style-type: none;
      height: 2.5rem;
      margin: 0;
      padding-left: 0.9375rem;
      padding-right: 1rem;
      background-color: $color-ey-confident-grey;
      & li {
        min-width: 7.625rem;
        float: left;
        font-size: 1rem;
        height: 100%;
        color: $color-white;
        & a,
        & button {
          text-align: center;
          cursor: pointer;
          height: 100%;
          line-height: 2.5rem !important;
          display: block;
          border-bottom: none;
          font-size: $font-size-4;
          font-family: $font-family-light !important;
          border: none; // override default button
          border-bottom: 0.25rem solid transparent;
          color: inherit; // override default a,button style
          &:hover,
          &:focus,
          &:active {
            outline: none;
            text-decoration: none;
          }
          &.active {
            border-bottom: 0.25rem solid $color-ey-yellow;
            background: $color-ey-interaction-overlays;
            font-family: $font-family-bold !important;
          }
        }
        & button {
          // override default button style
          background-color: inherit;
          min-width: inherit;
        }
        & a:hover {
          text-decoration: none; //override default a:href style
        }
      }
    }
  }
}

@mixin header-menu-group($padding-top: 0.5625rem) {
  background-color: transparent;
  border: none;
  height: 2.813rem;
  display: -webkit-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: $padding-top;
}

@mixin header-menu-group-state() {
  &:hover,
  &:focus,
  &:active {
    background-color: $color-ey-interaction-overlays;
    outline: none;
  }
}

/* header */
.page-header {
  position: relative;
  background: $color-ey-confident-grey;
  width: 100%;
  display: flex;
  grid-area: head;
  justify-content: space-between;
  align-items: center;
  & .header-group {
    display: -webkit-box !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    &__labels-container {
      margin-top: 0;
      padding-right: $spacing-03;
      padding-top: 0.5625rem;
      text-align: left;
      @media (max-width: $break-point-large) {
        min-height: 2rem;
        margin-top: 0;
        margin-bottom: 0;
      }
      & .header-group__labels {
        padding: 0;
        margin: 0;
      }
      & .header-group__sub-label {
        sup {
          top: 0.3rem;
        }
        font-size: 0.75rem !important;
        font-family: $font-family-notoSans !important;
        display: flex;
        margin: 0;
        padding: 0;
        line-height: 0.875rem !important;
        font-style: italic;
        color: $color-ey-grey-2;
        display: none;
        @media (max-width: $break-point-large) {
          font-size: 0.5rem !important;
          line-height: 0 !important;
          sup {
            top: 0;
          }
        }
        @media (max-width: 438px) {
          line-height: 1 !important;
        }
      }
    }
    &__aux-responsive {
      display: none;
      margin: auto;
      vertical-align: middle;
      margin-right: 1.0625rem;
      color: $color-white;
      @media (max-width: $break-point-large) {
        @include header-menu-group(0);
        button {
          @include header-menu-group-state;
        }
      }
      & .header-group__show-options,
      & .header-group__show-menu {
        background: transparent;
        border: none;
        height: 3.125rem;
        width: 3.125rem;
        color: $color-white;
        display: inline-block;
        line-height: 2;
        font-size: 1.1875rem;
      }
      & .header-group__show-options:before {
        font-family: 'Material Icons';
        content: 'build';
      }
      & .header-group__show-menu:before {
        font-family: 'Material Icons';
        content: 'menu';
      }
    }
  }
  & .userprofile-group {
    @include header-menu-group(0);
    margin-right: 0.3125rem;
    position: absolute;
    right: 0;
    & a {
      padding-top: 0.5625rem;
      padding-left: 0.5625rem;
      padding-right: 0.5625rem;
      height: 100%;
      @include header-menu-group-state;
    }
  }
  &:not(.page-header--sample) .userprofile-group {
    @media (max-width: $break-point-large) {
      display: flex;
    }
  }

  @media (max-width: $break-point-large) {
    height: 3.125rem;
  }
}

.header-group__image {
  margin: 0.6875rem 0.9375rem 0.75rem 0.9375rem;
  height: 1.688rem;
  width: 1.563rem;
  vertical-align: middle;
  border-style: none;

  @media (max-width: $break-point-large) {
    height: 1.688rem;
    width: 1.563rem;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.header-group__labels {
  font-family: $font-family-light !important;
  font-size: 0.9375rem !important;
  line-height: 0.6875rem !important;
  font-weight: 400;
  line-height: 11px;
  height: 11px;
  color: $color-white;
  padding: 0 0.9375rem;
  @media (max-width: $break-point-large) {
    padding-left: $spacing-03;
    padding-right: 0.375rem;
    padding-top: 0;
  }
  & .material-icons {
    @media (max-width: $break-point-large) {
      vertical-align: middle;
      font-size: 0.9375rem;
    }
  }
}

button.header-group__labels {
  //override button styles
  @include header-menu-group;
  @include header-menu-group-state;
}

.header-group__divider {
  width: 0;
  height: 2rem;
  margin: 0 0 0 0.5rem;
  border-left: 0.0625rem solid $color-ey-grey-1;
  @media (max-width: $break-point-large) {
    height: 2rem;
    margin: 0;
  }
}

/* User Profile on header */

.userprofile-group__help {
  @include fonts-col__sub-nav-default-state;
  color: $color-white;
  float: left;
  text-decoration: none;
  &:hover {
    color: $color-white;
    text-decoration: none;
    & > .material-icons {
      color: $color-white;
    }
  }

  .material-icons {
    color: $color-white;
    font-size: 1.25rem;
    position: relative;
    top: 0.3rem;
    margin-right: 0.5rem;
  }
}

/* User Logout dropdown component */

.userprofile__dropdown {
  @include header-menu-group(0);
  background: $color-ey-confident-grey;
  display: -webkit-inline-box !important;
  display: inline-flex !important;
  & a {
    @include fonts-col__sub-nav-default-state;
    color: $color-white;
  }
  .dropdown-toggle {
    &:active,
    &:focus,
    &:visited {
      color: $color-white;
    }
    &:hover {
      color: $color-white;
      text-decoration: none;
      .material-icons {
        color: $color-white;
      }
    }
    &::after {
      margin-left: 0.255em;
      vertical-align: 0.255em;
    }
    .material-icons {
      color: $color-white;
      font-size: 1.25rem;
      position: relative;
      top: 0.3rem;
      margin-right: 0.5rem;
    }
  }

  .dropdown-menu {
    padding: 0;
    min-width: 12.5rem;
    background: $color-white;
    border: 1px solid $color-ey-confident-grey;
    border-radius: 0px;
    box-shadow: -0.125rem -0.125rem 0.3125rem 0 rgba($color-ey-interaction-overlays, 0.2);
  }

  .dropdown-item,
  a.dropdown-item {
    font-family: $font-family-light;
    font-size: 0.875rem;
    color: $color-ey-confident-grey;
    line-height: 1.313rem;
    height: 2.813rem;
    padding-top: 0.625rem;
    padding-left: 0.875rem;
    width: calc(100% - 0.0625rem);
    &:hover,
    &:focus,
    &:active {
      &,
      .page-header & {
        background: $color-ey-grey-2a;
        outline: none;
      }
    }
    .material-icons {
      font-size: 1rem;
      position: relative;
      top: 0.1875rem;
      padding-right: 0.375rem;
    }
  }
}

/* User Profile as sample */

.page-header.page-header--sample {
  margin: auto;
  width: fit-content;
  .userprofile-group {
    position: relative;
    margin: 3rem;
    width: auto;
  }
}

$toast-height: 2.813rem;

.message {
  text-align: justify;
  padding: 0.5625rem 0.125rem;
  display: table;
  & .tool-tip-label {
    @include fonts-col__body-copy(1.5rem);
    font-weight: 300;
    color: $color-ey-confident-grey;
    margin: 0%;
    &:before {
      max-width: none;
    }
  }
  & .material-icons {
    height: 1.563rem;
    width: 1.563rem;
    vertical-align: middle;
    margin-right: 0.3125rem;
  }
}
.material-icons {
  .message--warning & {
    color: $color-warning;
  }
  .message--error & {
    color: $color-error;
  }
  .message--success & {
    color: $color-success;
  }
  .message--actions & {
    color: $color-success;
  }
  .message--info & {
    color: $color-ey-grey-1;
  }
}
.message__actions {
  min-width: 17.75rem;
  &__buttons {
    float: right;
    & .btn {
      font-family: $font-family-bold !important;
      padding: 0.25rem 1.125rem 0.25rem 1.063rem;
      margin: 0;
      height: auto;
      min-width: auto;
      color: $color-ey-confident-grey;
      &:focus {
        outline: 0.3125rem auto -webkit-focus-ring-color;
      }
    }
  }
}

.toast {
  margin: auto;

  width: 21.88rem;
  height: $toast-height;
  color: $color-ey-confident-grey;
  background: $color-white;
  box-shadow: 0 0 1rem 0 rgba($color-black, 0.2);
  border-radius: 0;
  border: none;

  &.toast--default {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  &__body {
    padding: 0;
    height: $toast-height;
    text-align: justify;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    label {
      font-family: $font-family-light !important;
      font-size: 0.875rem;
      margin: 0;
      height: $toast-height;
      line-height: $toast-height; //text v-alignment
    }
    .material-icons {
      height: 100%;
      width: $toast-height;
      text-align: center;
      line-height: $toast-height; //icon v-alignment
      color: $color-white;
    }
    label .material-icons {
      margin-right: 1.063rem;
    }
    &.message {
      &--info label .material-icons {
        background-color: $color-ey-grey-1;
      }
      &--success label .material-icons {
        background-color: $color-success;
      }
      &--warning label .material-icons {
        background-color: $color-warning;
      }
      &--error label .material-icons {
        background-color: $color-error;
      }
    }
  }
  &.toast--default,
  &.toast--bottom {
    border-radius: 0;
    & i {
      vertical-align: bottom;
    }
  }
  &.toast--bottom {
    background-color: $color-white;
    width: 21.88rem;
    left: 50%;
    position: fixed;
    transform: translate(-50%, 0);
    z-index: 9999;
    bottom: 2.188rem;
    &.show,
    &.showing {
      left: 50%;
      position: fixed;
      transform: translate(-50%, 0);
      -webkit-animation: show--toast 1s forwards; /* Safari 4.0 */
      animation: show--toast 1s forwards;
      animation-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    &.show.hidding {
      left: 50%;
      position: fixed;
      transform: translate(-50%, 0);
      -webkit-animation: hide--toast 1s forwards; /* Safari 4.0 */
      animation: hide--toast 1s forwards;
      animation-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    @media (max-width: 45rem) {
      min-width: 0;
      max-width: 21.88rem;
      height: auto;
      & .toast__body {
        text-align: center;
      }
    }
  }
  & .btn {
    float: right;
    height: $toast-height;
    border: none; //btn override
    margin: 0; //btn override
    padding: 0; //btn override
    min-width: auto; //btn override
    &--secondary {
      padding: 0 0.5rem;
    }
    &--dismiss {
      min-width: $toast-height; //btn override
      width: $toast-height;
      .material-icons {
        color: inherit;
        margin: 0;
        padding: 0;
        font-weight: bold;
      }
    }
  }
}

@keyframes show--toast {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 2.188rem;
  }
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hide--toast {
  0% {
    bottom: 2.188rem;
  }
  100% {
    bottom: 0;
  }
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

$modal-width: 800px;
$animation:
  opacity 0.2s linear,
  right 0.2s ease-out;

.modal {
  display: none;
  // cross component style
  &__header {
    background: $color-white;
    border-radius: 0;
    height: 3.75rem;
    border-bottom: 0.0625rem solid $color-ey-grey-2a;
    padding: 1.125rem 1.25rem 1.0625rem 1.25rem;
    &.dark-mode {
      background: $color-ey-confident-grey;
    }
  }
  &__icon {
    height: 1.3125rem;
    width: 1.3125rem;
    color: $color-red-05;
    line-height: 1.125rem;
    margin: 0 0.5rem 0 0;
    vertical-align: middle;
  }
  &__title {
    color: $color-ey-confident-grey;
    font-size: 1.25rem;
    margin: 0;
    &.dark-mode {
      color: $color-white;
    }
  }
  &__close {
    opacity: 1;
    margin: 0 !important;
    padding: 0 !important;
    background: none;
    text-shadow: none;
    border: none;
    &.close:not(:disabled):hover,
    &.close:not(:disabled):focus {
      opacity: 1;
    }
    & span {
      &::before {
        line-height: 1.5rem;
        //   color: white;
        color: $color-ey-confident-grey;
        content: 'close';
        font-family: 'Material Icons';
        font-size: 1.5rem;
      }
    }
  }

  &__body {
    padding: 1.875rem 2.5rem 3.125rem 1.25rem;
    p {
      @include fonts-col__sub-nav-default-state;
      text-align: left;
      padding: 0;
      margin: 0;
    }
  }

  &__footer {
    padding: 0.59375rem 0 0.59375rem 1.25rem;
    justify-content: flex-start;
    border-top: 0.0625rem solid $color-ey-grey-2a;
    &.modal__footer--center {
      justify-content: center;
    }
    & .btn {
      margin: 0;
      &:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    box-shadow: 0 0 0.6875rem 0.5rem rgba($color-black, 0.38);
    border: none;
    border-radius: 0;
  }
}
.modal-open {
  overflow: inherit;
}
.modal-side {
  display: none;
  &.modal-side--right {
    .modal-dialog {
      position: fixed;
      margin: auto;
      max-width: $modal-width;
      width: 100%;
      height: 100%;
      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
      z-index: 9999;
    }

    .modal-content {
      height: 100%;
      overflow-y: auto;
      border-radius: 0;
      z-index: 0;
      padding: 0;
    }
    .modal-body {
      padding: 1.875rem 2rem 0 2rem;
    }

    // Right side
    &.modal-side--fade {
      .modal-dialog {
        right: -$modal-width;
        -webkit-transition: $animation;
        -moz-transition: $animation;
        -o-transition: $animation;
        transition: $animation;
      }
    }
    &.modal-side--fade.show {
      .modal-dialog {
        right: 0;
      }
    }
  }

  &__header {
    border-radius: 0;
    //background: $color-ey-confident-grey;
    background: $color-white;
    padding: 1.125rem 1.25rem 1.0625rem 1.25rem;
  }
  &__title {
    font-size: 1.25rem;
    //color: $color-white;
    color: $color-ey-confident-grey;
    margin: 0;
    line-height: 1.5rem;
  }
  &__close {
    opacity: 1;
    opacity: 1;
    margin: 0 !important;
    padding: 0 !important;
    background: none;
    text-shadow: none;
    &.close:not(:disabled):hover,
    &.close:not(:disabled):focus {
      opacity: 1;
    }
    span {
      &::before {
        line-height: 1.5rem;
        //color: white;
        color: $color-ey-confident-grey;
        content: 'close';
        font-family: 'Material Icons';
        font-size: 1.5rem;
      }
    }
  }

  &__body {
    text-align: left;

    &-head {
      @include fonts-col__heading-6;
      color: $color-ey-confident-grey;
      padding: 0;
    }
    &-content {
      @include fonts-col__sub-nav-default-state;
      color: $color-ey-confident-grey;
      padding: 0;
    }
  }

  &__btn {
    margin-right: 1rem !important;
  }

  &__footer {
    $max-width: 470px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 0.0625rem solid $color-ey-grey-2a;
    padding: 0.59375rem 1.25rem 0.59375rem 1.25rem;
    @media (max-width: $max-width) {
      text-align: right;
    }
    .modal-side {
      &__back {
        margin: 0;
        &__button {
          font-size: $font-size-4;
          color: $color-ey-link-blue;
          border: none;
          background-color: transparent;
          text-align: left;
          @media (max-width: $max-width) {
            display: inline;
            position: static;
            margin: 0.625rem 2rem 0 0;
          }
          &::before {
            display: inline-block;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            vertical-align: middle;
            text-align: center;
            padding: 0;
            margin: 0 0.5rem 0 0;
            font-family: 'Material Icons';
            line-height: 1rem;
            font-size: $font-size-3;
            content: 'keyboard_arrow_left';
            color: $color-white;
            background-color: $color-ey-link-blue;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            /* Support for Firefox. */
            -moz-osx-font-smoothing: grayscale;
            /* Support for IE. */
            font-feature-settings: 'liga';
          }
        }
      }
      &__buttons {
        margin: 0;
        &__button {
          margin: 0;
          &:not(:first-child) {
            margin-left: 1rem;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .modal-lg-prev {
    max-width: 98%;
  }
}

//Global variables
$secondary-element-height: 3.125rem;
$z-index-modal: 9999;
@mixin navigation(
  $parentClass,
  $background-color-ul,
  $color-li-a,
  $font-size-li-a,
  $color-link-active,
  $color-link-disabled,
  $color-link-active-label,
  $ul-height: auto
) {
  $this: $parentClass;
  .btn-close {
    display: none;
  }
  ul {
    list-style-type: none;
    height: $ul-height;
    margin: 0rem;
    padding-left: 2.5rem;
    padding-right: 0.3125rem;
    background-color: $background-color-ul;
    & li {
      min-width: 6rem;
      float: left;
      font-size: 1rem;
      line-height: 1.5rem;
      height: 100%;
      & a:not([href]),
      & a,
      & button {
        padding: 0 1.25rem;
        height: 100%;

        &:not(.disabled) {
          cursor: pointer;
        }
        display: block;
        color: $color-li-a;
        font-family: $font-family;
        font-size: $font-size-li-a;
        text-align: center;

        &#{$this}__nav-link {
          background-color: transparent;
          border-bottom: 0.25rem solid transparent;
        }
        &:not(.disabled) {
          &:hover,
          &:focus {
            background-color: transparent;
            border-bottom: 0.25rem solid $color-link-active;
          }
        }
        &.active {
          background-color: transparent;
          color: $color-link-active-label;
          border-bottom: 0.25rem solid $color-link-active;
          font-family: $font-family-bold !important;
          font-weight: bold;
          outline: none;
          text-decoration: none;
        }
      }
      & a:not([href]).disabled,
      & a.disabled,
      & button.disabled {
        color: $color-link-disabled;
        cursor: default;
      }
      & button {
        // override to standard button style
        background-color: transparent;
        border: none;
        padding: 0 1.25rem;
      }
    }
    & a,
    & button {
      &:hover,
      &:focus,
      &:active {
        background-color: $color-ey-interaction-overlays;
        outline: none;
        text-decoration: none;
      }
    }
  }

  @media (max-width: $break-point-large) {
    ul {
      height: auto;
      display: flex;
      flex-direction: row;
      li {
        float: none;
      }
    }
  }
}
/* NAVIGATION BAR PRIMARY*/
.navigation-title {
  padding: $spacing-06 0;
  font-size: $font-size-6;
  font-family: $font-family-bold !important;

  &--margin-l {
    margin-left: 2.25rem !important;
  }
}
.navigation-bar-primary {
  position: relative;
  &--stack {
    margin-bottom: 3.125rem;
  }
  .navigation-bar-secondary {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    @media (max-width: $break-point-large) {
      position: static;
    }
  }

  @include navigation(
    '.navigation-bar-primary',
    $color-ey-confident-grey,
    $color-white,
    1rem,
    $color-ey-yellow,
    $color-ey-gray-tint-1,
    $color-white,
    3.6875rem
  );
  &__button-container {
    float: right;
    & button {
      background: 0 0;
      border: none;
      padding: 0 0 0 0.625rem;
      & i {
        display: inline-block;
        color: $color-white;
        font-size: 1.5rem;
        text-align: center;
        line-height: 1.5rem;
        padding-top: 1.3125rem;

        &:hover {
          color: $color-ey-yellow;
        }
      }
    }
  }
  & .navigation-bar-primary__nav-link {
    @include fonts-col__heading-6(3.6875rem);
    @media (max-width: $break-point-large) {
      @include fonts-col__main-nav-default-state(3.125rem);
    }
  }
}

/* NAVIGATION BAR UTILTY*/

.navigation-bar-utility {
  @include navigation(
    '.navigation-bar-utility',
    $color-ey-confident-grey,
    $color-white,
    1rem,
    $color-ey-yellow,
    $color-ey-gray-tint-1,
    $color-white,
    $secondary-element-height
  );
  @media (max-width: $break-point-large) {
    &__button {
      &--desktop {
        display: none;
      }
    }
  }
  &__button-container {
    float: right;
    & button {
      background: 0 0;
      border: none;
      padding: 0;
      margin: 0;
      height: $secondary-element-height;
      width: $secondary-element-height;
      & i {
        display: inline-block;
        color: $color-white;
        font-size: 1.125rem;
        text-align: center;
        line-height: 1.5rem;
        width: 1.5rem;
        position: relative;
      }
      & .badge {
        background: $color-ey-yellow;
        color: $color-ey-dark-gray;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        top: -0.4375rem;
        position: absolute;
        font-size: 0.5rem;
        padding-top: 0.3rem;
        right: -0.25rem;
      }
    }
  }
  @media (max-width: $break-point-large) {
    ul {
      height: 3.125rem;
    }
  }
}

/* NAVIGATION BAR SECONDARY*/

.navigation-bar-secondary {
  @include navigation(
    '.navigation-bar-secondary',
    $color-ey-interaction-overlays,
    $color-white,
    0.875rem,
    $color-ey-yellow,
    $color-ey-gray-tint-1,
    $color-white,
    $secondary-element-height
  );
  & .navigation-bar-secondary__nav-link {
    @include fonts-col__sub-nav-default-state(2.5rem);
  }
  @media (min-width: $break-point-large) {
    &.active {
      display: block;
    }
  }
}

/* NAVIGATION BAR TERTIARY*/

.navigation-bar-tertiary {
  overflow: auto;
  @include navigation(
    '.navigation-bar-tertiary',
    $color-white,
    $color-ey-confident-grey,
    1.125rem,
    $color-ey-confident-grey,
    $color-ey-gray-tint-1,
    $color-ey-confident-grey,
    2.8125rem
  );
  & ul li a:not([href]),
  & ul li a,
  & ul li button {
    padding-bottom: 0.5rem;
  }
  & .navigation-bar-tertiary__nav-link {
    @include fonts-col__sub-nav-default-state(2.8125rem);
  }
  -webkit-box-shadow: 0 0.1875rem 0.1875rem -0.125rem $color-shadow-2;
  -moz-box-shadow: 0 0.1875rem 0.1875rem -0.125rem $color-shadow-2;
  box-shadow: 0 0.1875rem 0.1875rem -0.125rem $color-shadow-2;

  @media (max-width: $break-point-large) {
    ul {
      height: $secondary-element-height;
      li {
        float: left;
      }
    }
  }
  @media (max-width: $break-point-smaller) {
    ul {
      height: auto;
      li {
        float: none;
      }
    }
  }
}

/* USAGE STACK */

/*Primary Level*/
.navigation-bar-stack-container {
  @media (min-width: $break-point-large) {
    .only-mobile {
      display: none;
    }
  }
  .navigation-bar-primary {
    $width-menu: 85%;
    $speed: 0.5s;
    $self: &;
    //Side right
    @media (max-width: $break-point-large) {
      background: $color-ey-confident-grey;
      position: fixed;
      right: -#{$width-menu};
      top: 0;
      width: $width-menu;
      max-width: 29rem;
      height: 100%;
      z-index: $z-index-modal;
      -webkit-transition: right $speed; /* For Safari 3.1 to 6.0 */
      transition: right $speed;
      &.showMobile {
        right: 0%;
        ul li {
          a,
          button {
            border-bottom: none;
          }
          button {
            width: 100%;
          }
        }
      }
      &.hideMobile {
        right: -#{$width-menu};
      }
      overflow-y: auto;
      .btn-close {
        display: block;
        width: 2.25rem;
        height: 2.25rem;
        color: $color-white;
        cursor: pointer;
        margin: 0.9375rem 1.25rem 1.875rem 0;
        padding: 0;
        border: 0;
        background: transparent;
        float: right;
        @include header-menu-group-state;
        i {
          width: 2.25rem;
          height: 2.25rem;
          font-size: 2.125rem;
          padding: 0;
          margin: 0;
          line-height: 2.25rem;
        }
      }
      ul {
        clear: both;
        list-style-type: none;
        height: auto;
        margin: 0rem;
        padding-left: 0;
        padding-right: 0;
        & .only-mobile:first-child {
          border-bottom: 0.0625rem solid $color-ey-gray-text;
        }
        & .only-mobile:last-child {
          border-top: 0.0625rem solid $color-ey-gray-text;
        }
        & li {
          background-color: $color-ey-confident-grey;
          float: none;
          font-size: 1rem;
          width: 100%;
          line-height: 1.5rem;
          height: 100%;

          li {
            background-color: $color-ey-interaction-overlays;
            a,
            button {
              &:not([href]):not(.disabled) {
                &:hover,
                &:focus {
                  font-family: $font-family-bold !important;
                  font-weight: bold;
                  border-bottom: none; //override
                }
              }
              &.active {
                border-left: solid 0.1875rem $color-ey-yellow;
                border-bottom: none; //override
              }
            }
          }

          & a:nth-last-child(n + 2),
          & button:nth-last-child(n + 2) {
            &::after {
              font-family: 'Material Icons';
              content: 'keyboard_arrow_down';
              color: $color-white;
              font-size: $font-size-8;
              width: 2rem;
              height: 100%;
              line-height: 4rem;
              -webkit-font-smoothing: antialiased;
              text-rendering: optimizeLegibility;
              /* Support for Firefox. */
              -moz-osx-font-smoothing: grayscale;
              /* Support for IE. */
              font-feature-settings: 'liga';
              position: absolute;
              right: 1.75rem;
              top: 0;
            }

            &[aria-expanded='true'] {
              &::after {
                content: 'keyboard_arrow_up';
              }
            }
          }

          & a:not([href]),
          & a,
          & button {
            padding: 0.3125rem 1.875rem;
            height: 100%;
            text-align: left;
            font-size: 1rem !important;
            color: $color-white !important;
            font-family: $font-family-light !important;
            font-weight: 300;
            font-size: 1rem;
            position: relative;

            i {
              vertical-align: middle;
              margin-right: 0.625rem;
            }

            &:not(.disabled) {
              cursor: pointer;
            }

            &:not([href]):not(.disabled) {
              &:hover,
              &:focus {
                color: $color-white !important;
                background-color: $color-ey-interaction-overlays;
                border-bottom: none;
                font-family: $font-family-bold !important;
                font-weight: bold;
                outline: none;
                text-decoration: none;
              }
            }

            &:hover {
              text-decoration: none;
            }

            &#{$self}__nav-link {
              border-bottom: none !important;
            }

            &.active {
              color: $color-white !important;
              background-color: $color-ey-interaction-overlays;
              border-bottom: none;
              font-family: $font-family-light !important;
            }
            &:hover {
              text-decoration: none;
            }
          }
          & a:not([href]).disabled,
          & a.disabled,
          & button.disabled {
            color: $color-ey-gray-tint-1;
          }
        }
      }
    }

    & .navigation-bar-stack-primary__nav-link {
      @include fonts-col__main-nav-default-state;
    }
  }
}

/*Secondary Level*/

.navigation-bar-stack-container .navigation-bar-stack-secondary {
  @include navigation(
    '.navigation-bar-stack-secondary',
    $color-gray-alt-1,
    $color-white,
    0.875rem,
    $color-ey-yellow,
    $color-ey-gray-tint-1,
    $color-white,
    2.5rem
  );
  & .navigation-bar-stack-secondary__nav-link {
    @include fonts-col__sub-nav-default-state;
  }
}

/*Tertiary Level*/

.navigation-bar-stack-container .navigation-bar-stack-tertiary-container {
  @include navigation(
    '.navigation-bar-stack-tertiary',
    $color-ey-grey-4,
    $color-ey-dark-gray,
    1.125rem,
    $color-ey-link-blue,
    $color-ey-gray-tint-1,
    $color-ey-confident-grey,
    2.8125rem
  );
  margin: 0rem;
  background-color: $color-gray-alt-4;
  border-bottom: 0.125rem solid $color-ey-gray-tint-2;
  padding: 1rem 1rem 0 2.5rem;
  & ul {
    padding-left: 0%;
  }
  & h3 {
    text-align: left;
    margin: 2rem 0;
    color: $color-ey-dark-gray;
    @include fonts-col__heading-6--bold;
  }
  & .navigation-bar-stack-tertiary__nav-link {
    @include fonts-col__heading-6;
  }
  & .breadcrumbs label {
    display: flex;
    @include fonts-col__sub-nav-default-state;
  }
}

.navigation-bar-stack-container .navigation-bar-utility__button-container {
  $speed: 0.5s;
  &.showMobile {
    height: auto;
    top: 0;
    display: block;
  }
  &.hideMobile {
    height: 0;
    display: none;
  }
  & .btn-close,
  & span:not(.badge) {
    display: none;
  }
  .navigation-bar-utility__button__buttons {
    clear: both;
    float: right;
    margin-right: 0;
  }
  @media (max-width: $break-point-large) {
    background: $color-ey-interaction-overlays;
    position: fixed;
    right: 0;
    display: none;
    width: 100%;
    height: 0;
    z-index: $z-index-modal;
    -webkit-transition: height $speed;
    transition: height $speed;
    & .btn-close {
      display: block;
      margin: 0.9375rem 1.25rem 0 0 !important;
    }
    & .navigation-bar-utility__button {
      min-width: 5rem;
      height: 4.6875rem;
      margin-bottom: 0.5rem;
      margin-top: 0;
      text-align: center;
      color: $color-white;
      @include fonts-col__instructional-text(1.125rem);
      margin-left: 1.5625rem;
      & span {
        display: block;
      }
    }
  }
}

.navigation-bar-modal {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba($color: $color-black, $alpha: 0.5);
  z-index: ($z-index-modal)-1;
}

/*Vertical Tab*/
.navigation-vertical-tab {
  & ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    & li {
      display: flex;
    }
  }
  &__nav-link {
    padding: 0.875rem 3rem 0.75rem 1.25rem;
    font-family: $font-family-light !important;
    width: 9.5625rem;
    cursor: pointer;
    color: inherit; //override a[href] style
    border: none; //override button style
    border-left: 0.375rem solid transparent; //reset left border
    text-align: left; //override button style
    background-color: transparent;
    &.active {
      background: rgba($color-ey-grey-4, 0.6);
      border-left: 0.375rem solid $color-ey-confident-grey;
      font-family: $font-family-bold !important;
    }
    &:hover {
      color: inherit; //override a[href] style
      text-decoration: none; //override a[href] style
    }
  }
}

/* Only for design system */
.active-pg-show {
  color: $color-ey-confident-grey;
  font-family: $font-family;
}

.pagination-wrapper {
  margin-top: 2rem;
}

/* ++++++++++ PAGINATION STYLES ++++++++++ */

.pagination-block {
  color: $color-ey-confident-grey;
  font-size: 0.875rem;
  padding-left: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  & .pagination-block__page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.8125rem;
    width: 2.8125rem;
    border-bottom: 0.1875rem solid transparent;
    &:hover {
      border-bottom: 0.1875rem solid $color-ey-confident-grey;
    }
    &--numeration {
      width: 5.9375rem;
      font-family: $font-family-bold;
      & span {
        font-family: $font-family-bold;
      }
      &:hover {
        border-bottom: 0.1875rem solid transparent;
      }
    }
    & > button {
      @include fonts-col__instructional-text;
      line-height: 0 !important; //override
      width: 100%;
      height: 100%;
      text-align: center;
      margin: 0;
      padding: 0;
      border: none;
      background-color: transparent;
      &:focus {
        outline: 0.3125rem auto -webkit-focus-ring-color;
      }
      & i {
        font-size: 1.6875rem;
      }
    }
  }

  & &__active {
    border-bottom: 0.1875rem $color-ey-confident-grey solid;
  }

  & &__prev-next {
    color: $color-ey-grey-1;
    top: 0.4375rem;
    padding: 0.125rem 0 0 0;
  }

  & &__ellipses {
    color: $color-ey-grey-1;
    font-size: 0.875rem;
    padding: 0.3125rem;
  }
}

/* ++++++++++ PAGINATION Items Per Page STYLES ++++++++++ */
// Note: this component is also using dropdown style and script

$pagination-dropdown-width: 6.4375rem;
$pagination-dropdown-height: 2.5rem;
@mixin bordered($color: transparent) {
  outline: none;
  border: 0.0625rem solid $color;
}

.dropdown.dropdown--pagination {
  height: $pagination-dropdown-height;
  min-height: $pagination-dropdown-height;
  width: $pagination-dropdown-width;
  .dropdown-toggle {
    height: $pagination-dropdown-height;
    min-height: $pagination-dropdown-height;
    width: $pagination-dropdown-width;

    .textinput-group__textinput {
      @include fonts-col__form-field-inputs;
      font-size: 0.875rem !important; //override size important
      padding-top: 0.15rem;
      padding-left: 0.5625rem;
      @include bordered(transparent);
      cursor: pointer;
      background-color: $color-white;
    }

    .textinput-group__label {
      cursor: pointer;
      .dropdown__expand_less,
      .dropdown__expand_more {
        top: 1.625rem;
      }
    }
  }

  .dropdown-toggle[aria-expanded='true'] > .textinput-group__textinput:not(.error):not(.disabled) {
    font-size: 0.875rem !important; //override dropdown important style
  }

  .dropdown-menu {
    width: $pagination-dropdown-width;
    min-width: $pagination-dropdown-width;
    @include bordered($color-ey-confident-grey);
    box-shadow: 0 0.125rem 0.1875rem 0 $color-shadow-2;
    margin-top: 0.0625rem;
    border-radius: 0; //override bootstrap

    .dropdown-item {
      @include fonts-col__sub-nav-default-state;
      color: $color-ey-confident-grey;
      height: $pagination-dropdown-height;
      width: 100%;
      line-height: 1.4rem;
      padding-left: 0.9375rem;
      cursor: pointer;
      &:hover,
      &.active {
        background: $color-ey-grey-2a;
      }
    }
  }
}
.dropdown--pagination__label {
  color: $color-ey-confident-grey;
  text-align: left;
  line-height: 2.625rem;
  width: fit-content;
  margin-left: 1.25rem;
  span {
    @include fonts-col__instructional-text;
    margin-right: 1.063rem;
  }
}

.pill {
  //text
  color: $color-white;
  font-size: 0.75rem;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.125rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  display: inline-block;

  //box
  border-radius: $spacing-02;
  height: 1.25rem;
  min-width: 3.125rem;

  //margin
  margin: $spacing-02 $spacing-06 $spacing-02 0;

  &.pill--approved {
    background: $color-green-03;
    color: $color-ey-confident-grey;
  }
  &.pill--failed {
    background: $color-red-03;
    color: $color-ey-confident-grey;
  }
  &.pill--hold {
    background: $color-orange-03;
    color: $color-ey-confident-grey;
  }
  &.pill--open {
    background: $color-ey-confident-grey;
  }
  &.pill--closed {
    background: $color-white;
    color: $color-ey-confident-grey;
    border: solid 0.0625rem $color-ey-confident-grey;
  }
  &.pill--green {
    background: $color-green-04; //not found-->#2eb757;
  }
  &.pill--teal {
    background: $color-teal-04; //not found-->#28aca9;
  }
  &.pill--blue {
    background: $color-blue-04; //not found-->#188be5;
  }
  &.pill--purple {
    background: $color-purple-04; //not found-->#3d1089;
  }
  &.pill--maroon {
    background: $color-maroon-04; //not found-->#750e5d;
  }
  &.pill--red {
    background: $color-red-04; //not found-->#ff4236;
  }
  &.pill--orange {
    background: $color-orange-04;
  }
  &.pill--group-by {
    color: $color-ey-grey-1;
    background: $color-white;
  }
}

.pill-wrapper {
  margin: 0 0 2.6875rem 0;
}

.story-card-rollup-container {
  display: inline-flex;
}

.story-card-rollup {
  margin: 0.5rem 1rem;
  color: $color-ey-dark-gray;

  &__amount {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.875rem;
    padding: 0;
    margin: 0;
    &--active {
      color: $color-ey-link-blue;
    }
    & .material-icons {
      font-size: $font-size;
      color: $color-ey-red;
      margin: 0.25rem 0rem 0rem 0rem;
      vertical-align: top;
    }
  }

  &__sub-title {
    font-size: 0.75rem;
    line-height: 1.0625rem;
    text-transform: uppercase;
    padding: 0;
    margin: 0.25rem 0rem 0rem 0rem;
    display: block;
    &--active {
      color: $color-ey-link-blue;
    }
  }
}

/* TOOL TIP DEFINITION */

.tool-tip-definition {
  display: inline-block;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  font-style: normal;
  margin: 0.25rem;
  &:after {
    filter: drop-shadow(0rem 0.3125rem 0.1875rem $color-shadow-2);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: block;
    width: 0rem;
    height: 0rem;
    border-style: solid;
    content: '';
    position: absolute;
    opacity: 0;
    visibility: hidden;

    border-color: transparent transparent $color-ey-confident-grey transparent;
    bottom: -0.5rem;
    left: 50%;
    transform: translate(-50%, 0rem);

    border-width: 0rem 0.5rem 0.5rem 0.5rem;
    z-index: 999;
  }
  &:before {
    filter: drop-shadow(0rem 0rem 0.5rem $color-shadow-2);
    display: block;
    @include fonts-col__body-copy;
    font-size: 0.75rem !important; //override
    content: attr(tool-tip-content);
    position: absolute;
    background: $color-ey-confident-grey;
    color: $color-white;
    padding: 0.5rem;
    line-height: 1.188rem;
    font-style: normal;
    min-width: 10.81rem;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
    bottom: 0rem;
    left: 50%;
    transform: translate(-50%, calc(100% + 0.5rem)); //IE10, IE11, and Edge < 14 don't support using calc() inside a transform
    box-sizing: border-box;
  }
  &.tool-tip-definition--light:before {
    background: $color-white;
    color: $color-ey-confident-grey;
  }
  &:hover:before,
  &:hover:after,
  &.tool-tip-definition--force-show:before,
  &.tool-tip-definition--force-show:after {
    display: block;
    visibility: visible;
    opacity: 1;
  }
  &.tool-tip-definition--light {
    &:after {
      border-color: transparent transparent $color-white transparent;
    }
    &.tool-tip-position--top:after {
      border-color: $color-white transparent transparent transparent;
    }
    &.tool-tip-position--left:after {
      border-color: transparent $color-white transparent $color-white;
    }
    .tool-tip-definition__icon {
      color: $color-ey-interaction-overlays; //no token, from design asset
      font-size: $font-size-7;
    }
  }
  &__icon {
    color: $color-ey-interaction-overlays;
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    font-size: $font-size-7;
  }
}

/* TOOL TIP LABEL */

.tool-tip-label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  font-style: normal;
  margin: 4rem;
  &:after {
    filter: drop-shadow(0rem 0.3125rem 0.1875rem $color-shadow-2);
    width: 0rem;
    height: 0rem;
    border-style: solid;
    content: '';
    position: absolute;
    opacity: 0;
    visibility: hidden;

    border-width: 0rem 0.5rem 0.5rem 0.5rem;
    border-color: transparent transparent $color-ey-confident-grey transparent;
    bottom: -0.5rem;
    left: 50%;
    transform: translate(-50%, 0rem);
  }
  &:before {
    filter: drop-shadow(0rem 0rem 0.5rem $color-shadow-2);

    @include fonts-col__body-copy;
    content: attr(tool-tip-content);
    position: absolute;
    background: $color-ey-confident-grey;
    color: $color-white;
    padding: 0.5rem;
    line-height: 1.188rem;
    font-style: normal;
    min-width: 2.5rem;
    max-width: 5rem;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    z-index: 999;

    bottom: 0rem;
    left: 50%;
    //transform: translate(-50%, calc(100% + 0.5rem)); //IE10, IE11, and Edge < 14 don't support using calc() inside a transform
    transform: translate(-50%);
    top: calc(100% + 0.5rem);
    height: 150%;
    height: -webkit-fit-content;
    height: -moz-max-content;
    height: fit-content;
    box-sizing: border-box;
  }

  &:hover:before,
  &:hover:after {
    visibility: visible;
    opacity: 1;
  }
}

/* TOOL TIP POSITION */

.tool-tip-position {
  display: inline-block;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  font-style: normal;
  margin: 4rem;
  &:after {
    filter: drop-shadow(0rem 0.3125rem 0.1875rem $color-shadow-2);
    width: 0rem;
    height: 0rem;
    border-style: solid;
    content: '';
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
  &:before {
    filter: drop-shadow(0rem 0rem 0.5rem $color-shadow-2);
    @include fonts-col__body-copy;
    font-size: 0.75rem !important; //override
    content: attr(tool-tip-content);
    position: absolute;
    background: $color-ey-confident-grey;
    color: $color-white;
    padding: 0.5rem;
    line-height: 1.188rem;
    font-style: normal;
    min-width: 10.81rem;
    max-width: 15rem;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
  }
  &:hover:before,
  &:hover:after {
    visibility: visible;
    opacity: 1;
  }

  /*top*/

  &--top:before {
    display: inline-block;

    top: 0rem;
    left: 50%;
    bottom: auto;
    //transform: translate(-50%, calc(-100% - 0.4375rem)); //IE10, IE11, and Edge < 14 don't support using calc() inside a transform
    transform: translate(-50%, -100%);
    top: -0.9375rem;
    box-sizing: border-box;
  }

  &--top:after {
    display: block;
    border-width: 1.125rem 0.8125rem 0 0.8125rem;
    border-color: $color-ey-confident-grey transparent transparent transparent;
    top: -0.5rem;
    left: 50%;
    transform: translate(-50%, -0.4375rem);
  }

  /*bottom*/

  &--bottom:before {
    bottom: 0rem;
    left: 50%;
    //transform: translate(-50%, calc(100% + 0.5rem)); //IE10, IE11, and Edge < 14 don't support using calc() inside a transform
    transform: translateX(-50%);
    top: calc(100% + 1.25rem);
    height: 200%;
    height: -webkit-fit-content;
    height: -moz-max-content;
    height: fit-content;
    box-sizing: border-box;
  }

  &--bottom:after {
    border-width: 0rem 0.8125rem 1.125rem 0.8125rem;
    border-color: transparent transparent $color-ey-confident-grey transparent;
    bottom: -1.25rem;
    left: 50%;
    transform: translate(-50%, 0rem);
  }

  /*left*/

  &--left:before {
    left: 0rem;
    top: 50%;
    height: 200%;
    height: -webkit-fit-content;
    height: -moz-max-content;
    height: fit-content;
    //transform: translate(calc(-100% - 0.5rem), -50%); //IE10, IE11, and Edge < 14 don't support using calc() inside a transform
    transform: translate(-100%, -50%);
    left: -1.25rem;
    box-sizing: border-box;
  }

  &--left:after {
    border-width: 0.8125rem 0rem 0.8125rem 1.125rem;
    border-color: transparent transparent transparent $color-ey-confident-grey;
    left: -1.25rem;
    top: 50%;
    transform: translate(0rem, -50%);
  }

  /*right*/

  &--right:before {
    right: 0;
    top: 50%;
    //transform: translate(calc(100% + 0.52rem), -50%); //IE10, IE11, and Edge < 14 don't support using calc() inside a transform
    transform: translateY(-50%); //IE10, IE11, and Edge < 14 don't support using calc() inside a transform
    left: calc(100% + 1.25rem);
    box-sizing: border-box;
  }

  &--right:after {
    border-width: 0.8125rem 1.125rem 0.8125rem 0rem;
    border-color: transparent $color-ey-confident-grey transparent transparent;
    right: -1.25rem;
    top: 50%;
    transform: translate(0rem, -50%);
  }

  //override for ie/edge. ie-edge class can be on any parent element at any level
  .ie-edge & {
    &:before {
      display: inline-table;
    }
  }
}

/* Tooltip text */
.tooltip {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  opacity: 1;
  z-index: auto;
  .tooltiptext {
    filter: drop-shadow(0rem 0rem 0.5rem $color-shadow-2);
    visibility: hidden;
    width: fit-content;
    min-width: 17.8125rem;
    white-space: nowrap;
    background-color: $color-ey-confident-grey;
    color: $color-white;
    text-align: center;
    padding: 0.625rem 0.9375rem;
    min-height: 4.375rem;
    z-index: 1;
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

    ul {
      list-style: none;
      padding-inline-start: 0;
      padding: 0; //override for edge
      margin-bottom: 0;
      li {
        text-align: left;

        color: $color-white;
        font-size: 0.75rem;
        font-family: $font-family-light !important;
        font-weight: 300;
        line-height: 1.125rem;
        margin: 0.3125rem 0;
        span {
          font-family: $font-family-bold !important;
        }
      }
    }

    .material-icons {
      font-size: 1.125rem;
      vertical-align: bottom;
      margin-right: 0.3125rem;
    }
  }
  &.tooltip--light {
    i {
      color: $color-ey-interaction-overlays; //no token, from design asset
    }
  }
  &.tooltip--light .tooltiptext {
    background-color: $color-white;
    color: $color-ey-confident-grey;
    ul li {
      color: $color-ey-confident-grey;
    }
    .material-icons {
      color: $color-ey-confident-grey;
      &.success {
        color: $color-success;
      }
      &.error {
        color: $color-red-06;
      }
    }
  }
  .tooltiptext::after {
    content: '';
    position: absolute;
    top: calc(100% - 0.0063rem); //less just a bit to remove hairline
    left: 50%;
    margin-left: 0rem;
    border-width: 0.8125rem 1.125rem 0.8125rem 0rem;
    border-style: solid;
    border-color: $color-ey-confident-grey transparent transparent transparent;
  }
  &.tooltip--light .tooltiptext::after {
    border-color: $color-white transparent transparent transparent;
  }
}

/* Tooltip arrow */

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover,
.tooltip.tooltip--force-show {
  .tooltiptext {
    visibility: visible;
    opacity: 1;
    position: absolute;
  }
}

/* Tooltip on the top */
.tooltip.tooltip--top {
  .tooltiptext {
    /* Position the tooltip text */
    position: absolute;
    transform: translate(-50%, -100%);
    top: -0.9375rem;
  }
  .tooltiptext::after {
    transform: scaleY(2);
    left: calc(50% - 1.438rem);
  }
}

/* Tooltip on the Right */
.tooltip.tooltip--right {
  .tooltiptext {
    /* Position the tooltip text */
    top: 0;
    transform: translateY(-1.4875rem);
    left: calc(100% + 1.4875rem);
  }
  .tooltiptext::after {
    top: 1.375rem;
    left: -1.125rem;
    border-color: transparent $color-ey-confident-grey transparent transparent;
  }
  &.tooltip--light .tooltiptext::after {
    border-color: transparent $color-white transparent transparent;
  }
}

/* Tooltip on the Bottom */
.tooltip.tooltip--bottom {
  .tooltiptext {
    /* Position the tooltip text */
    position: absolute;
    top: 2.25rem;
    left: -50%;
    transform: translate(-50%);
  }
  .tooltiptext::after {
    top: -1.65rem;
    left: 50%;
    transform: scaleY(2);
    border-color: transparent transparent $color-ey-confident-grey transparent;
  }
  &.tooltip--light .tooltiptext::after {
    border-color: transparent transparent $color-white transparent;
  }
}

/* Tooltip on the Left */
.tooltip.tooltip--left {
  .tooltiptext {
    /* Position the tooltip text */
    position: absolute;
    transform: translate(-100%, -21%);
    left: -1.25rem;
  }
  .tooltiptext::after {
    top: 1.375rem;
    left: calc(100% - 0.0625rem);
    border-color: transparent transparent transparent $color-ey-confident-grey;
  }
  &.tooltip--light .tooltiptext::after {
    border-color: transparent transparent transparent $color-white;
  }
}

.datarollup {
  color: $color-ey-confident-grey;
  //d-flex flex-row justify-content-center
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  & > button {
    //override button style
    border: none;
    background-color: transparent;
  }
}

.datarollup__card {
  $margin-card: 2.8125rem;
  cursor: pointer;
  width: 9.5rem;
  // d-flex flex-column justify-content-center
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 1.063rem $margin-card 0 $margin-card;
  position: relative;

  &::after {
    content: ' ';
    width: 0.0625rem;
    height: 3.625rem;
    background: $color-ey-gray-border-1;
    position: absolute;
    top: 0;
    right: -#{$margin-card};
  }
}
.datarollup__card:first-child {
  margin-left: 0;
}
.datarollup__card:last-child {
  margin-right: 0;
  &::after {
    display: none;
  }
}
.datarollup__number {
  height: 1.875rem;
  font-size: 2.5rem;
  font-family: $font-family-light !important;
  font-weight: 300;
  text-align: center;
  line-height: 1.875rem;
  .datarollup__card--active > & {
    font-family: $font-family !important;
    font-weight: 400;
  }
}
.datarollup__state {
  height: 0.75rem;
  font-size: 0.75rem;
  font-family: $font-family-light !important;
  font-weight: 300;
  text-align: center;
  //other
  margin-top: 0.8125rem;
  .datarollup__card--active > & {
    font-weight: 600;
  }
}
.datarollup__status {
  height: 0.4375rem;
  background: transparent;
  border-radius: 0;
  //other
  margin: 1.063rem 0.1875rem 0 0.1875rem;
  .datarollup__card--active > & {
    background: $color-ey-yellow;
  }
}

.datarollup__spacer {
  width: 5.938rem;
  //d-flex flex-row justify-content-center
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  &::after {
    content: ' ';
    width: 0.0625rem;
    height: 3.625rem;
    background: $color-ey-gray-border-1;
    position: absolute;
    transform: translateY(1.3rem);
  }
}
.datarollup__bar {
  width: 0.0625rem;
  height: 3.625rem;
  background: $color-ey-gray-border-1;
}

//******* Fast Filters style  ************

//Style for the Fast Filters (component only)
.fastfilter {
  height: 2.813rem;

  //vertical center all
  display: -webkit-inline-box !important;
  display: inline-flex !important;
  align-content: center !important;
  flex-wrap: wrap !important;
  -webkit-box-align: center !important;
  align-items: center !important;

  //margin-padding
  margin: 0 $spacing-02 0 0;
  padding: 0 $spacing-02 0 $spacing-02;

  //text style
  color: $color-ey-confident-grey; //#444555; //no token
  font-size: 0.75rem;
  font-family: $font-family-light !important;
  font-weight: 300;
  letter-spacing: 0;

  //default
  border-width: 0;
  background: $color-white;

  //clickable
  cursor: pointer;
  & > * {
    pointer-events: none;
  }

  //Main Borders
  &.fastfilter--state1 {
    border-width: 0.0625rem;
    border-style: solid;
    border-color: $color-disabled;
    background: $color-ey-grey-4;
    &.fastfilter--active {
      border-color: rgba(116, 116, 128, 0.5); //no token
      background: $color-white;
      border-bottom-width: 0.125rem;
      .fastfilter__number {
        margin-top: 0.0625rem; //adjust due to bottom border
      }
      &.fastfilter--all {
        border-bottom-color: $color-ey-grey-1;
      }
      &.fastfilter--complete {
        border-bottom-color: $color-success;
      }
      &.fastfilter--pending {
        border-bottom-color: $color-warning;
      }
      &.fastfilter--expired {
        border-bottom-color: $color-red-06;
      }
      &.fastfilter--light {
        border-bottom-color: $color-ey-confident-grey;
      }
      &.fastfilter--dark {
        border-bottom-color: $color-ey-confident-grey;
      }
    }
  }
}

//number (90)
.fastfilter__number {
  height: 2rem;
  color: $color-white;
  border-radius: 1rem;
  font-size: 0.875rem;
  text-align: center;
  letter-spacing: 0;
  line-height: 1.1875rem;
  margin: 0 $spacing-01 0 0;
  border-width: 0.0625rem;
  border-style: solid;
  padding: 0.25rem 1.0625rem;
  font-family: $font-family-light !important;
  :not(.fastfilter--active):not(.fastfilter--state1) > & {
    background: $color-white;
    color: $color-ey-confident-grey;
    border-width: 0.0625rem;
    border-style: solid;
    border-radius: 1rem;
  }
  .fastfilter--all & {
    background: $color-ey-grey-1;
    border-color: $color-ey-grey-1;
  }
  .fastfilter--complete & {
    background: $color-success;
    border-color: $color-success;
  }
  .fastfilter--pending & {
    background: $color-warning;
    border-color: $color-warning;
    color: $color-ey-confident-grey;
  }
  .fastfilter--expired & {
    background: $color-red-06;
    border-color: $color-red-06;
  }
  .fastfilter--light & {
    background: $color-white;
    color: $color-ey-confident-grey;
  }
  .fastfilter--dark & {
    background: $color-ey-confident-grey;
    color: $color-white;
  }
}

//Style for the Wrapper (not for the component)
.wrapper__fastfilters {
  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
}
.wrapper__fastfilters--row {
  display: -webkit-inline-box !important;
  display: inline-flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: row !important;
}

.wrapper__fastfilters-label {
  width: 5.938rem;
  height: 1.875rem;
  color: $color-ey-gray-tint-1; //#8a8b97; //no token
  font-size: 0.625rem;
  font-family: $font-family-light;
  font-weight: 300;
  line-height: 1.875rem;
  text-align: left;
}

$slider-speed: 0.3s;
.stepper {
  .stepper__controls {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  &--horizontal {
    .stepper__controls {
      &::after {
        content: '';
        position: absolute;
        top: 49.5%;
        width: 100%;
        height: 0.125rem;
        background-color: $color-ey-grey-2;
        margin-right: 0.3125rem;
      }

      overflow: hidden;
      counter-reset: step;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row wrap;
      flex-flow: row wrap;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;

      background-color: $color-white;
      position: relative;
      margin-bottom: 2rem;

      .stepper__control {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        flex-wrap: nowrap;
        -webkit-flex-wrap: nowrap;
        align-items: center;
        background-color: $color-white;
      }

      .stepper__title {
        padding: 0 0.4375rem;
        background-color: $color-white;
        color: $color-ey-confident-grey;
        font-size: $font-size-3;
        line-height: 0.9375rem;
        z-index: 2;
      }
      .stepper__dot {
        position: relative;
        padding-left: 0.4375rem;
        z-index: 2;
        background: $color-white;
        &::before {
          /* CSS for creating steper block before the li item*/
          content: '';
          counter-increment: step;
          height: 1.25rem;
          width: 1.25rem;
          line-height: 1.875rem;
          border: 0.0625rem solid $color-ey-confident-grey;
          display: inline-block;
          text-align: center;
          margin: 0;
          border-radius: 50%;
          background-color: $color-white;
          vertical-align: middle;
          z-index: 1;
          position: relative;
        }
      }
      .stepper__control:first-child {
        .stepper__dot {
          padding-left: 0;
          &::after {
            left: 0.0625rem;
          }
        }
      }

      .stepper__control.stepper__control--done {
        .stepper__dot {
          &::before {
            font-family: 'Material Icons';
            line-height: 1rem;
            font-size: $font-size-3;
            content: 'check';
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            /* Support for Firefox. */
            -moz-osx-font-smoothing: grayscale;
            /* Support for IE. */
            font-feature-settings: 'liga';
          }
        }
      }
      .stepper__control.stepper__control--active {
        .stepper__title {
          font-weight: bold;
          font-family: $font-family-bold !important;
        }

        .stepper__dot {
          &::before {
            background-color: $color-green-04;
            box-shadow: inset 0 0 0 0.1875rem $color-white;
            content: '';
          }
        }
      }
    }

    .stepper__content {
      .stepper__slide {
        height: 0;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        width: 0;
        opacity: 0;
        -webkit-transition:
          width $slider-speed,
          opacity $slider-speed; /* For Safari 3.1 to 6.0 */
        transition:
          width $slider-speed,
          opacity $slider-speed;

        &-title {
          margin-bottom: 0;
        }

        .textinput-group {
          margin: 2rem 0 1.5rem 0;
        }
      }

      .stepper__slide--active {
        width: 100%;
        opacity: 1;
        height: 100%;
        overflow: visible;
      }
    }
  }
  &--vertical {
    position: relative;
    .stepper__controls {
      .stepper__control {
        display: flex;
        &:not(:first-child) {
          margin-top: 1.6875rem;
        }
        &:last-child {
          & .stepper__dot {
            height: 1.55rem;
          }
        }
        & .stepper__dot {
          height: 1.25rem;
          width: 1.25rem;
          z-index: 0;
          background-color: $color-white;
          line-height: 1rem;
          &::before {
            box-shadow: inset 0 0 0 0.125rem $color-white;
            content: '';
            height: 1.25rem;
            width: 1.25rem;
            display: inline-block;
            border: 0.0625rem solid $color-ey-confident-grey;
            border-radius: 50%;
          }
        }
        &--active {
          .stepper__dot {
            &::before {
              background-color: $color-green-06;
            }
          }
          .stepper__title {
            margin-left: 0.5625rem;
            font-size: $font-size-4;
            font-family: $font-family-bold !important;
          }
        }
        &--done {
          .stepper__dot {
            &::before {
              font-family: 'Material Icons';
              line-height: 1.2rem;
              font-size: 0.75rem;
              content: 'check';
              -webkit-font-smoothing: antialiased;
              text-rendering: optimizeLegibility;
              -moz-osx-font-smoothing: grayscale;
              font-feature-settings: 'liga';
            }
          }
        }
        &:not(.stepper__control--active) .stepper__title {
          margin-left: 0.5625rem;
          font-size: $font-size-4;
          font-family: $font-family-light !important;
        }
      }
      &::before {
        content: ' ';
        position: absolute;
        display: block;
        top: 0;
        left: 0.59375rem;
        bottom: 0.0625rem;
        height: 100%;
        width: 0.0625rem;
        background-color: $color-ey-confident-grey;
      }
    }
  }
}

.spinner {
  $border-color: $color-gray-alt-2;
  $active-area-color: $color-ey-confident-grey;
  $spinner-speed: 0.7s;
  $animation: linear;
  display: flex;
  align-items: center;
  &__label {
    color: $color-ey-grey-1;
    font-size: $font-size-3;
    font-weight: normal;
    margin: 1.25rem 0 0 0;
    text-align: center;
  }
  &__icon {
    &::before {
      content: '';
      display: inline-block;
      box-sizing: border-box;
      border-radius: 50%;
      animation: spinner $spinner-speed $animation infinite;
    }

    &--small {
      width: 1rem;
      height: 1rem;
      &::before {
        height: 1rem;
        width: 1rem;
        border: 0.125rem solid $border-color;
        border-top-color: $active-area-color;
      }
    }

    &--medium {
      width: 1.5rem;
      height: 1.5rem;
      &::before {
        height: 1.5rem;
        width: 1.5rem;
        border: 0.1875rem solid $border-color;
        border-top-color: $active-area-color;
      }
    }

    &--large {
      width: 2.5rem;
      height: 2.5rem;
      &::before {
        height: 2.5rem;
        width: 2.5rem;
        border: 0.25rem solid $border-color;
        border-top-color: $active-area-color;
      }
    }
    &--xxlarge {
      width: 6.25rem;
      height: 6.25rem;
      margin: 0 auto;
      &::before {
        height: 6.25rem;
        width: 6.25rem;
        border: 0.25rem solid $border-color;
        border-top-color: $active-area-color;
      }
    }
    @keyframes spinner {
      to {
        transform: rotate(360deg);
      }
    }
  }
}

.progress-group {
  & .progress-group__label {
    font-size: $font-size-3 !important;
    font-family: $font-family-light !important;
    height: 1.1875rem;
    color: $color-ey-confident-grey;
    text-align: left;
    margin-bottom: 0.125rem;
  }
  & .progress {
    //override bootstrap
    height: 0.375rem;
    background: $color-ey-grey-3;
    border-radius: 0;
  }
  & .progress-bar {
    //override bootstrap
    background: $color-ey-confident-grey;
  }
}

.progress-group--fileloader {
  border: 0.0625rem solid $color-border-2;
  height: 2.8125rem;

  & .progress-group__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include fonts-col__body-copy;
    color: $color-ey-confident-grey;
    background: $color-ey-grey-5;
    text-align: left;
    padding: 0.8125rem 1.25rem;
    height: 2.5rem;
    margin: 0;
    & .material-icons {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.5rem;
      margin-right: 0.625rem;
      color: $color-ey-confident-grey;
    }
    & div,
    & i {
      color: $color-ey-confident-grey;
    }
    & div {
      @include fonts-col__body-copy(0.75rem);
    }
    & div:nth-child(2) {
      flex-grow: 1;
    }
    & .progress-group__label-size {
      font-size: 0.75rem !important;
    }

    & .progress-group__close {
      width: 1.5rem;
      height: 1.5rem;
      font-size: $font-size-4;
      border: none;
      background-color: transparent;
      color: $color-ey-confident-grey;
      padding: 0;
      margin: 0 0 0 0.9375rem;
      i {
        margin: 0;
      }
    }
  }
  & .progress {
    //override bootstrap
    height: 0.1875rem;
    border-radius: 0;
    background-color: $color-ey-grey-5;
  }
  & .progress-bar {
    //override bootstrap
    background: $color-ey-confident-grey;
  }
}

.filter {
  display: -webkit-inline-box !important;
  display: inline-flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;

  height: 1.25rem;
  background: $color-white;
  border: 0.0625rem solid $color-ey-confident-grey;
  border-radius: 1rem;
  min-width: 0;

  //text
  color: $color-ey-confident-grey;
  font-size: 0.625rem;
  font-weight: normal;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.125rem;

  //padding 0, set margins on child elements
  padding: 0 0 0.125rem 0;

  //margin
  margin: 0.875rem;
  &:first-child {
    margin-left: 0;
  }

  &.filter--large {
    height: 2rem;
    border-radius: 1rem;
  }

  &:hover,
  &:focus {
    background-color: $color-ey-confident-grey;
    color: $color-white;
    button.filter__close {
      color: $color-white;

      &:hover,
      &:focus {
        background-color: #585860; // No token;
        border-radius: 50%;
      }
    }
  }
}

.filter__label {
  font-size: 0.75rem;
  font-family: $font-family-light !important;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0;
  line-height: 0.875rem;

  //set margin - left,right
  margin: 0;
  &:first-child {
    margin-left: 0.5rem;
  }
  &:only-child {
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
  //end of: set margin - left,right

  .filter--large & {
    font-size: 0.875rem;

    &:first-child {
      margin-left: 0.625rem;
    }
  }
}
.filter__down {
  margin: 0 0.25rem 0 0.625rem;
  .material-icons {
    font-size: 0.75rem;
    .filter--large & {
      font-size: 0.875rem;
    }
    vertical-align: middle;
    cursor: pointer;
  }
}

.filter__close {
  margin: 0 0.25rem 0 0.25rem;
  .filter--large & {
    margin: 0 0.625rem 0 0.25rem;
  }
  .material-icons {
    font-size: 0.75rem;
    .filter--large & {
      font-size: 1.25rem;
    }
    vertical-align: middle;
    cursor: pointer;
  }
}

button.filter__close {
  // override styles for <button>
  color: $color-ey-confident-grey;
  background-color: transparent;
  border: none;
  padding: 0;
  width: 1rem;
  height: 1rem;
  line-height: 0;
  margin-top: 0.0625rem;
}
.filter--large {
  button.filter__close {
    color: $color-ey-confident-grey;
    background-color: transparent;
    border: none;
    padding: 0;
    width: 1.4375rem;
    height: 1.4375rem;
    line-height: inherit;
    margin-top: 0;
  }
}

.slider {
  $height: 0.125rem;
  $handlerHeight: 1.563rem;
  $handlerWidth: 1.563rem;
  display: block;
  position: relative;
  height: $handlerHeight;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  &__start,
  &__end {
    color: $color-ey-grey-1;
    font-size: $font-size-3;
    font-family: $font-family-light !important;
    position: absolute;
    bottom: -1.563rem;
  }
  &__start {
    left: 0;
  }
  &__end {
    right: 0;
  }
  &__line {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    width: calc(100% - 2.25rem);
    left: 1.125rem;
    top: 0.75rem;
    height: $height;
    border-radius: $height;
    background: $color-ey-confident-grey;
    z-index: 0;
    overflow: hidden;
    span {
      display: block;
      height: 100%;
      background: #e1e1e4; //no token
    }

    &__right {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__control {
    &--left,
    &--right {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      display: block;
      position: absolute;
      height: $handlerHeight;
      width: $handlerWidth;
      z-index: 2;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }

      span {
        display: block;
        width: 100%;
        height: 100%;
        background: $color-white;
        border: 0.0625rem solid $color-ey-confident-grey;
        border-radius: 50%;
        &:hover,
        &:focus,
        &:active {
          background: $color-ey-confident-grey;
          border-color: transparent;
          outline: none;
        }
      }
      // set disabled after other state pseudo-classes
      &.disabled {
        cursor: not-allowed;
        span {
          background: $color-ey-grey-2;
          border-color: transparent;
        }
      }
      .slider__value-indicator {
        display: none;
        pointer-events: none;
        position: absolute;
        top: -1.625rem;
        left: 50%;
        color: $color-ey-confident-grey;
        font-size: $font-size-3;
        font-family: $font-family-light !important;

        &--active {
          display: block;
        }
      }
    }
  }
}

$btn-with: 3.125rem;
.search {
  position: relative;
  width: 100%;
  label ~ & {
    width: 16.88rem;
  }
  // border: solid 0.0625rem $color-border-2 !important;

  &.focus input,
  input:focus,
  input:active {
    padding-top: 0.0625rem;
  }
  label.focus {
    display: none;
  }

  &__filter-btn {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: $btn-with;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    div {
      font-size: $font-size-3;
      color: $color-ey-gray-alt;
      font-family: $font-family-light !important;
      position: absolute;
      bottom: 0.125rem;
      left: 0;
      width: 100%;
    }

    i {
      font-size: $font-size-8;
      color: $color-ey-gray-alt;
      position: absolute;
      top: 0.125rem;
      left: 0;
      width: 100%;
    }
  }
  &__result {
    position: absolute;
    top: 100%;
    width: 100%;
    max-height: 32.375rem;
    overflow-y: auto;
    background-color: $color-white;
    border: solid 0.0625rem $color-ey-gray-tint-1;
    text-align: left;

    &__people,
    &__info {
      .main-title {
        position: relative;
        padding: $spacing-04 $spacing-03 0 $spacing-03;
        font-family: $font-family-bold;
        font-size: $font-size-2;
        color: $color-ey-grey-2;
        line-height: 0.75rem;
        margin: 0 0 $spacing-01 0;

        &::before {
          content: ' ';
          position: absolute;
          display: block;
          width: 95%;
          height: 0.0625rem;
          top: 0;
          left: 2.5%;
          background: $color-ey-gray-tint-1;
          margin: $spacing-02 0;
        }
      }
    }

    &__item {
      line-height: $font-size-3;
      font-family: $font-family-light !important;
      font-size: $font-size-3;
      color: $color-ey-confident-grey;
      padding: $spacing-03;
      border: solid 0.0625rem $color-white;
      text-transform: lowercase;
      display: flex;
      align-items: center;
      .title {
        font-family: $font-family-light !important;
        font-size: $font-size-5;
        line-height: $spacing-04;
      }
      .desc {
        font-family: $font-family-light !important;
        font-size: $font-size-3;
        text-transform: capitalize;
        span {
          font-family: $font-family-light !important;
          font-size: $font-size-3;
          color: $color-ey-gray-tint-1;
          font-style: italic;
          font-weight: normal;
          text-transform: capitalize;
        }
      }
      .avatar--image {
        width: 2.5rem;
        height: 2.5rem;
      }
      span {
        font-weight: bold;
      }
      &:hover {
        background-color: $color-ey-grey-4;
        border: solid 0.0625rem $color-border;
        cursor: pointer;
        text-decoration: none;
        color: $color-ey-confident-grey;
        border-left: none;
        border-right: none;
      }
    }
  }

  &::after {
    @include material-icons($font-size-8);
    content: 'search';
    line-height: 2.8125rem;
    position: absolute;
    top: 0;
    right: 0;
    width: $spacing-08;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
  }
  &.focus::after {
    content: 'close';
  }
  &.disabled::after {
    color: $color-ey-grey-2;
    cursor: not-allowed;
  }
}

.search--filters {
  padding-left: $btn-with;

  .textinput-group__label {
    padding-left: $btn-with;
  }

  .search__result {
    width: calc(100% - 3.125rem) !important;
  }
}

.breadcrumb {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  border-radius: 0;
  background-color: $color-ey-confident-grey;
  min-height: 4.6875rem;
  padding: 0;
  width: 100%;
  a {
    color: $color-white;
    text-decoration: none;
  }
  &__item:first-child {
    padding: 0px 0.625rem 0 0.3125rem;
  }
  &__item {
    color: $color-white;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    position: relative;
    padding: 0 $spacing-04;
    &::after {
      content: '';
      width: 1px;
      height: 75%;
      position: absolute;
      top: 15%;
      right: 0;
      background-color: $color-gray-alt-1;
    }
  }

  &__section {
    font-size: $font-size-3;
    font-family: $font-family-light !important;
    cursor: pointer;
    padding-right: 0.75rem;
    &--back-btn {
      &::before {
        width: 1.5625rem;
        display: inline-block;
        vertical-align: middle;
        font-family: 'Material Icons';
        content: 'keyboard_arrow_left';
        font-size: $font-size-9;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga';
      }
    }
  }

  &__page {
    font-size: $font-size-5;
    font-family: $font-family !important;
    cursor: pointer;
  }

  &--light {
    background-color: $color-white;

    a {
      color: $color-ey-confident-grey;
    }
    .breadcrumb__item {
      color: $color-ey-confident-grey;
      &::after {
        background-color: $color-border-2 !important;
      }
    }
  }
}

.upload-group {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .upload-group__filestatus-label {
    height: 2.8125rem;
  }
  & > *:not(:last-child):not(.upload-group__delete):not(.upload-group__filestatus--menu) {
    margin-bottom: 1.25rem;
  }

  &__dragdrop {
    width: 100%;
    height: 6.25rem;
    background: $color-white;
    border: 0.0625rem solid $color-ey-grey-2;
    border-style: dashed;
    border-radius: 0;

    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1.25rem;

    .material-icons {
      color: $form-color-default;
      height: 2.188rem;
      font-size: 2rem;
      margin-bottom: 0.625rem;
      width: 2.1875rem;
      height: 1.5rem;
    }
  }
  &__dragdrop-label {
    @include fonts-col__body-copy(1.375rem);
    height: 1.375rem;
    color: $color-ey-confident-grey;
    font-weight: 300;
    text-align: center;
    & a {
      color: $color-ey-link;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__filestatus {
    $height: 2.813rem;
    border: 0.0625rem solid $color-border-2;
    background: $color-ey-grey-5;

    & > *:first-child {
      padding: 0.8125rem 1.25rem;
    }

    &--spinner,
    &--menu {
      display: flex;
      flex-direction: row;
    }

    &--menu {
      margin-bottom: 0.3125rem;
      align-items: center;
    }

    &-label {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      align-items: center;

      @include fonts-col__body-copy;
      height: 1rem;
      color: $color-ey-confident-grey;
      background: inherit;
      text-align: left;
      padding-left: 0.875rem;
      height: $height;
      line-height: $height !important; //override
      margin: 0;
      & .material-icons {
        font-size: 1.5rem;
        margin-right: 0.3125rem;
        height: $height;
        line-height: $height; //override
      }
      & div,
      & i {
        color: inherit;
      }
      & div {
        font-family: $font-family-light !important;
        font-size: 0.875rem;
        line-height: $height; //override
      }
      & div:nth-child(2) {
        flex-grow: 1;
      }
      & div:last-child {
        margin-right: 1.25rem;
      }
      & .upload-group__filestatus-size {
        font-size: $font-size-3;
      }
      & .progress-group__close {
        width: 1.5rem;
        height: 1.5rem;
        font-size: $font-size-4;
        border: none;
        background-color: transparent;
        color: $color-ey-confident-grey;
        padding: 0;
        margin: 0 0 0 0.9375rem;
        line-height: 1.5rem;
        i {
          margin: 0;
          margin-right: 0;
          height: inherit;
          line-height: 1.5rem;
        }
      }
      & .progress-group__no-icon {
        display: none;
      }
    }

    & .spinner {
      margin-right: 0.75rem;
      &__icon {
        width: $height;
        height: $height;
        padding-top: 0.375rem;
        &:before {
          height: 2rem;
          width: 2rem;
        }
        &-progress {
          position: relative;
          height: $height;
          line-height: $height;
          top: calc(-1 * #{$height});
          color: $color-ey-confident-grey;
          font-size: 0.6875rem;
        }
      }
    }

    &-size {
      margin-right: 1.25rem;
    }

    & .progress {
      //override bootstrap
      height: 0.1875rem;
      background: inherit;
      border-radius: 0;
    }
    & .progress-bar {
      //override bootstrap
      background: $color-ey-confident-grey;
    }

    & .overflow-menu-toggle.dropdown-toggle {
      .material-icons {
        font-size: 2rem;
        line-height: $height;
        width: $height;
        height: $height;
        background: inherit;
      }
    }

    // for format: <i></i> and <button><i></i></button>
    &-delete.material-icons,
    & button.upload-group__filestatus-delete {
      color: $color-ey-confident-grey;
      font-size: 1.25rem;
      line-height: $height;
      padding-left: 0.875rem;
      height: $height;
      text-align: left;
      background: inherit;
      &:first-child {
        padding-left: 1.25rem;
      }
    }

    // for format: <button><i></i></button> (accessibility-tabstop)
    & button.upload-group__filestatus-delete {
      // override button style
      border: none;
      background-color: inherit;
      padding: 0 0.7rem;
      margin: 0 0.3rem 0 0.4rem;
      .material-icons {
        height: 1.25rem;
        line-height: 2.8125rem;
        font-size: inherit;
      }
      &:first-child {
        padding-left: 0.7rem;
      }
      & + .upload-group__filestatus-label {
        padding-left: 0;
      }
    }

    & .textinput-group__errmsg {
      padding: 0;
      width: auto;
      font-family: $font-family-light !important;
      margin: 0 0.625rem 0 0;
      &:before {
        position: static;
        float: right;
        margin: 0 0 0 0.3125rem;
      }
    }

    & .textinput-group__btn {
      border: none;
      font-family: $font-family-bold !important;
      font-size: 0.875rem;
      margin: 0.9375rem;
      background: transparent;
    }
  }

  &__delete {
    margin-bottom: 0.625rem;
    text-align: left;
    a {
      @include fonts-col__sub-nav-selected-state;
      color: $color-ey-link;
      font-weight: bold;
      height: 1.375rem;
      &:hover {
        color: $color-ey-link;
        text-decoration: none;
      }
    }
  }

  & .textinput-group__textinput {
    font-size: 0.875rem !important; //override mixin
  }
}

*:not(i):not(mat-icon):not(.check-label):not(.check-label-disabled):not(.eyfontlight):not(.eyfontbold):not(.fr-wrapper):not(.fr-element):not(
    .fr-view *
  ) {
  font-family: $font-family;
}

*.eyfontregular {
  font-family: $font-family !important;
}

*.eyfontlight {
  font-family: $font-family-light !important;
}

*.eyfontbold {
  font-family: $font-family-bold !important;
}

html {
  font-size: $font-size !important;
}
