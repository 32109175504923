@import './mixins/icon-mixins';
@import 'variables';

.emp-icon {
  font-size: 1.5rem !important;
  cursor: pointer;

  &-confident-grey {
    color: $color-ey-confident-grey !important;
  }

  &-ey-yellow {
    color: $color-ey-yellow !important;
  }
  &-disabled {
    color: $color-ey-grey-2 !important;
  }
}

.icon-ico_Excel_Plain:before {
  content: '\e950';
  color: $color-ey-grey-3;
}

.icon-ico_Excel_Plain_Draft:before {
  color: $color-orange-03 !important;
  opacity: 85%;
}

.icon-ico_PPoint_Plain_Draft:before {
  color: $color-orange-03 !important;
  opacity: 85%;
}

.icon-ico_PDF_Plain_Draft:before {
  color: $color-orange-03 !important;
  opacity: 85%;
}

.icon-ico_Word_Plain_Draft:before {
  color: $color-orange-03 !important;
  opacity: 85%;
}

.icon-ico_PDF_Plain:before {
  content: '\e952';
  color: $color-ey-grey-3;
}

.icon-ico_PPoint_Plain:before {
  content: '\e953';
  color: $color-ey-grey-3;
}

.icon-ico_Word_Plain:before {
  content: '\e954';
  color: $color-ey-grey-3;
}

.icon-ico_Excel_Dark:before {
  content: '\e950';
  color: $color-ey-confident-grey;
}

.icon-ico_PDF_Dark:before {
  content: '\e952';
  color: $color-ey-confident-grey;
}

.icon-ico_PPoint_Dark:before {
  content: '\e953';
  color: $color-ey-confident-grey;
}

.icon-ico_Word_Dark:before {
  content: '\e954';
  color: $color-ey-confident-grey;
}

.icon-ico_condition_colored:before {
  content: '\e902';
  color: $color-ey-grey-1;
  background-color: $color-white;
}

/*
.emp-icon {
    font-style: normal;
    &.mail {
        @include create-icon('../../icons/ico_mail.svg');
    }
    &.info {
        @include create-icon('../../icons/ico_tooltip.svg');
    }
    &.check {
        @include create-icon('../../icons/ico_check.svg');
    }
    &.delete {
    @include create-icon('../../icons/ico_delete.svg');
    }
    &.plus {
    @include create-icon('../../icons/ico_plus.svg');
    }
    &.ico_shevron_down_big {
      @include create-icon('../../icons/ico_shevron_down_big.svg');

    }
    &.ico_shevron_up_big {
      @include create-icon('../../icons/ico_shevron_down_big.svg');
      transform: rotate(180deg)
    }

    &.ico_edit {
      @include create-icon('../../icons/ico_edit.svg');
    }
    &.ico_delete {
      @include create-icon('../../icons/ico_delete.svg');
    }
    &.ico_check {
      @include create-icon('../../icons/ico_check.svg');
    }
    &.ico_benefits {
      @include create-icon('../../icons/ico_benefits.svg');
    }
    &.ico_more {
      @include create-icon('../../icons/ico_more.svg');
    }
    &.ico_sync {
      @include create-icon('../../icons/ico_sync.svg');
    }
}

.btn {
  .emp-icon {
  vertical-align: bottom;
  &.plus {
    @include create-icon('../../icons/ico_plus.svg');
    height: 22px;
  }
  }
}

.icon-ey-yellow {
  color: $color-ey-yellow !important;
}
.icon-ey-confident-grey {
  color: $color-ey-confident-grey !important;

}
*/
