.restricted-combinations {
  &.card {
    border-radius: 0;
    overflow: visible;
  }

  .card-header {
    padding: 0.625rem;
    background-color: $color-ey-grey-5;
  }
}
