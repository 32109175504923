@import 'assets/scss/variables';
@import 'assets/scss/tax-design-system/typography-mixins';
@import 'assets/scss/icons';
@import 'assets/scss/modal';
@import 'assets/scss/buttons';
@import 'assets/scss/tooltip';
@import 'assets/scss/navigation';
@import 'assets/scss/anchor';
@import 'assets/scss/to-be-removed.scss';
@import 'assets/scss/icomoon';
@import 'assets/scss/spacing';
@import 'assets/scss/froala-overrides';
@import 'assets/scss/properties-editor';
@import 'assets/scss/accordion';
@import 'assets/scss/ey-summary';
$prompt-padding: 3.75rem;

html {
  scroll-behavior: auto !important;
}

body.modal-open {
  overflow: auto !important;
  padding-right: 0px !important;
}

.btn-border-color {
  border-color: $color-ey-confident-grey;
}

.pill-content-from-data-text-attr::after {
  content: attr(data-text);
}
.login-page {
  background-image: url('./assets/images/login_back/login_bg1.jpg');
  background-size: cover;
  width: 100%;
  height: 100vh;

  h1 {
    font-size: $font-size-8;
  }

  h2 {
    font-size: $font-size-5;
    color: $color-ey-grey-2;
    margin-bottom: 2.5rem;
  }

  button {
    width: 100%;
    margin: 0px;
  }
  .login-prompt {
    background-color: $color-ey-confident-grey;
    color: $color-white;
    padding: $prompt-padding;
  }

  .environment {
    color: $color-white;
    font-size: $font-size-3;
  }

  .logo-container {
    top: 40px;
    left: 40px;
    position: absolute;

    .logo {
      width: 60px;
      height: 60px;
      margin-bottom: 2rem;
    }

    .support-mail {
      font-size: 0.875rem;
      color: $color-ey-grey-2;
    }

    .material-icons {
      font-size: 1rem;
      position: relative;
      top: 0.1875rem;
      padding-right: 0.375rem;
    }
  }

  @media (max-width: 1100px) {
    .logo-container {
      position: static;
    }
  }
}

@media (min-width: 1580px) {
  .col-xxl-2 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.pill-light-gray {
  background-color: $color-ey-grey-4 !important;
  border: 1px solid #8d8d93 !important;
  color: black !important;
}

.pill-pill-non-std {
  height: auto;
  line-height: 0.6875rem;
  border-radius: 2px;
  font-size: 0.6875rem;
  padding: 0.1875rem 0.375rem;
}

/*
@media (-webkit-min-device-pixel-ratio: 1.20) {
  html, body {
    font-size: 75% !important;
    background-color: white !important;
  }
}
 */

.overflow-x-auto {
  overflow-x: auto;
}

.text-transform-none {
  text-transform: none !important;
}

@mixin fonts-col__heading-7($line-height: 1.5rem) {
  font-size: $font-size-3 !important;
  font-family: $font-family-light !important;
  line-height: $line-height !important;
}
@mixin fonts-col__heading-7--bold($line-height: 1.5rem) {
  font-size: $font-size-3 !important;
  font-family: $font-family-bold !important;
  line-height: $line-height !important;
}

@mixin fonts-col__heading-8($line-height: 1.5rem) {
  font-size: $font-size-2 !important;
  font-family: $font-family-light !important;
  line-height: $line-height !important;
}
@mixin fonts-col__heading-8--bold($line-height: 1.5rem) {
  font-size: $font-size-2 !important;
  font-family: $font-family-bold !important;
  line-height: $line-height !important;
}

.fonts-col__heading-7 {
  @include fonts-col__heading-7;
}
.fonts-col__heading-7--bold {
  @include fonts-col__heading-7--bold;
}

.fonts-col__heading-8 {
  @include fonts-col__heading-8;
}
.fonts-col__heading-8--bold {
  @include fonts-col__heading-8--bold;
}
