.properties-editor {
  .card {
    border: none !important;
    border-radius: 0% !important;
    border-bottom: 1px solid $color-ey-grey-2a !important;
    overflow: visible;

    &-header {
      padding: 0px !important;
      background-color: $color-white !important;
      border: none !important;
    }
  }

  .card:only-child,
  .card.expanded:last-child {
    border: none !important;
  }
}

.required-option-error-icon {
  font-size: $font-size-5 !important;
  cursor: pointer;
  padding-left: 0.175rem;
  padding-top: 0.05rem;
}

.required-option-errMsg {
  font-size: $font-size-3 !important;
  font-family: 'EYInterstate', 'Noto Sans', Arial, sans-serif !important;
  color: $color-red-06;
  width: 100%;
  text-align: left;
}
