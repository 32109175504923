a {
  &.anchor-button {
    color: $color-ey-confident-grey;
    display: inline-block;
    padding: 2px;
    text-decoration: none;
    &:hover {
      background-color: $color-ey-grey-2;
      text-decoration: none;
    }

    &:disabled,
    &.disabled,
    &.disabled:hover {
      color: $color-ey-grey-2a;
    }

    i {
      vertical-align: middle;
    }
  }
}

@mixin preview-link {
  a.preview-link {
    color: $color-blue-06;
    font-size: 0.875rem;
    position: relative;
    text-decoration: none;
  }

  a.preview-link:hover {
    text-decoration: none !important;
    cursor: pointer;
  }

  a.preview-link > span {
    vertical-align: super;
    top: 1px;
    position: relative;
  }
}
