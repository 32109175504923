.tooltip--holder {
  width: 2rem;
  margin: 0.6rem;
}

.emp-tooltip {
  .arrow {
    display: none;
  }

  .tooltip-inner {
    border-radius: 0;
    border: none;
    background-color: $color-white;
    color: $color-ey-confident-grey;
    padding: 0.5rem 0.625rem;
    box-shadow: 0rem 0rem 0.625rem $color-ey-confident-grey;
  }
}
