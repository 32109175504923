$header-height: 4.5rem;
$button-height: 2.813rem;
$footer-padding-y: 0.59375rem;
$default-modal-width: 32.5rem;
$xl-modal-width: 60rem;

@mixin max-modal-body-height-single-header($modal-margin-y) {
  height: calc(100vh - #{2 * $modal-margin-y} - #{$header-height} - #{2 * $footer-padding-y} - #{$button-height});
  max-height: calc(100vh - #{2 * $modal-margin-y} - #{$header-height} - #{2 * $footer-padding-y} - #{$button-height});
}

@mixin max-modal-body-height-double-header($modal-margin-y) {
  height: calc(100vh - #{2 * $modal-margin-y} - #{2.2 * $header-height} - #{2 * $footer-padding-y} - #{$button-height});
  max-height: calc(100vh - #{2 * $modal-margin-y} - #{2.2 * $header-height} - #{2 * $footer-padding-y} - #{$button-height});
}

@mixin max-modal-body-height($modal-margin-y, $doubleHeader) {
  @if ($doubleHeader) {
    @include max-modal-body-height-double-header($modal-margin-y);
    padding: 1.875rem 2.5rem 3.125rem 1.25rem;
  } @else {
    @include max-modal-body-height-single-header($modal-margin-y);
  }
}

@mixin modal-styles($doubleHeader) {
  .modal {
    &-dialog {
      margin: $modal-margin-bg-heavy-y auto;

      @media (max-width: $break-point-medium) {
        margin: $modal-margin-md-heavy $modal-margin-md-heavy;
        max-width: none;
      }

      @media (max-width: $break-point-small) {
        margin: $modal-margin-sm-heavy;
      }
    }

    &__body {
      @include max-modal-body-height($modal-margin-bg-heavy-y, $doubleHeader);

      @media (max-width: $break-point-medium) {
        @include max-modal-body-height($modal-margin-md-heavy, $doubleHeader);
      }

      @media (max-width: $break-point-small) {
        @include max-modal-body-height($modal-margin-sm-heavy, $doubleHeader);
      }
    }
  }
}
/* overrides for single type of modal default*/
.modal-fullscreen {
  min-width: 96%;
  height: auto;
}

@media (min-width: $break-point-small) {
  .modal-dialog:not(.modal-lg):not(.modal-sm):not(.modal-xl) {
    max-width: $default-modal-width;
  }
}
@media (min-width: $break-point-extra-large) {
  .modal-xl {
    max-width: $xl-modal-width;
  }
}

/* default modal styling */

.modal {
  &-dialog {
    margin: $modal-margin-bg-y auto;

    @media (max-width: $break-point-small) {
      margin: $modal-margin-sm;
    }
  }

  &__body {
    padding: 1.875rem 2.5rem 3.125rem 1.25rem;
    @include max-modal-body-height($modal-margin-bg-y, false);
    overflow-y: auto;
    @media (max-width: $break-point-small) {
      @include max-modal-body-height($modal-margin-sm, false);
    }

    p {
      margin-bottom: $spacing-03;
    }
  }
}

/* modal variations */

.modal-heavy {
  @include modal-styles(false);
}

.modal-heavy-double-header {
  @include modal-styles(true);
}

.modal-step {
  color: $color-ey-grey-2;
}

.modal-info {
  .modal {
    min-height: 200px !important;

    &-dialog {
      @media (max-width: $break-point-medium) {
        margin: $modal-margin-md-heavy $modal-margin-md-heavy;
        max-width: none;
      }

      @media (max-width: $break-point-small) {
        margin: $modal-margin-sm-heavy;
      }
    }

    &__body {
      height: auto;
      max-height: 500px;

      @media (max-width: $break-point-medium) {
        height: auto;
        max-height: 500px;
      }

      @media (max-width: $break-point-small) {
        height: auto;
        max-height: 500px;
      }
    }
  }
}

.modal-tall {
  .modal {
    min-height: 200px !important;

    &-dialog {
      @media (max-width: $break-point-medium) {
        margin: $modal-margin-md-heavy $modal-margin-md-heavy;
        max-width: none;
      }

      @media (max-width: $break-point-small) {
        margin: $modal-margin-sm-heavy;
      }
    }

    &__body {
      height: auto;
      max-height: 768px;

      @media (max-width: $break-point-medium) {
        height: auto;
        max-height: 768px;
      }

      @media (max-width: $break-point-small) {
        height: auto;
        max-height: 768px;
      }
    }
  }
}
