.m-10 {
  margin: 0.625rem !important;
}
.ms-10 {
  margin-left: 0.625rem !important;
}
.me-10 {
  margin-right: 0.625rem !important;
}
.mt-10 {
  margin-top: 0.625rem !important;
}
.mb-10 {
  margin-bottom: 0.625rem !important;
}
.mx-10 {
  margin-left: 0.625rem !important;
  margin-right: 0.625rem !important;
}
.my-10 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.m-20 {
  margin: 1.25rem !important;
}
.ms-20 {
  margin-left: 1.25rem !important;
}
.me-20 {
  margin-right: 1.25rem !important;
}
.mt-20 {
  margin-top: 1.25rem !important;
}
.mb-20 {
  margin-bottom: 1.25rem !important;
}
.mx-20 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}
.my-20 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.m-30 {
  margin: 1.875rem !important;
}
.ms-30 {
  margin-left: 1.875rem !important;
}
.me-30 {
  margin-right: 1.875rem !important;
}
.mt-30 {
  margin-top: 1.875rem !important;
}
.mb-30 {
  margin-bottom: 1.875rem !important;
}
.mx-30 {
  margin-left: 1.875rem !important;
  margin-right: 1.875rem !important;
}
.my-30 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.m-40 {
  margin: 2.5rem !important;
}
.ms-40 {
  margin-left: 2.5rem !important;
}
.me-40 {
  margin-right: 2.5rem !important;
}
.mt-40 {
  margin-top: 2.5rem !important;
}
.mb-40 {
  margin-bottom: 2.5rem !important;
}
.mx-40 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}
.my-40 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.p-10 {
  padding: 0.625rem !important;
}
.ps-10 {
  padding-left: 0.625rem !important;
}
.pe-10 {
  padding-right: 0.625rem !important;
}
.pt-10 {
  padding-top: 0.625rem !important;
}
.pb-10 {
  padding-bottom: 0.625rem !important;
}
.px-10 {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}
.py-10 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.p-20 {
  padding: 1.25rem !important;
}
.ps-20 {
  padding-left: 1.25rem !important;
}
.pe-20 {
  padding-right: 1.25rem !important;
}
.pt-20 {
  padding-top: 1.25rem !important;
}
.pb-20 {
  padding-bottom: 1.25rem !important;
}
.px-20 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
.py-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.p-30 {
  padding: 1.875rem !important;
}
.ps-30 {
  padding-left: 1.875rem !important;
}
.pe-30 {
  padding-right: 1.875rem !important;
}
.pt-30 {
  padding-top: 1.875rem !important;
}
.pb-30 {
  padding-bottom: 1.875rem !important;
}
.px-30 {
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important;
}
.py-30 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.p-40 {
  padding: 2.5rem !important;
}
.ps-40 {
  padding-left: 2.5rem !important;
}
.pe-40 {
  padding-right: 2.5rem !important;
}
.pt-40 {
  padding-top: 2.5rem !important;
}
.pb-40 {
  padding-bottom: 2.5rem !important;
}
.px-40 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
.py-40 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.d-block {
  display: block;
}
