@import 'variables';

div.ey-summary-number {
  color: $color-ey-yellow;
  font-size: 2rem;
  max-height: 2.5rem;
}
div.ey-summary-label {
  color: $color-ey-grey-3;
  font-size: 1rem;
  line-height: 1.875rem;
  vertical-align: bottom;
  max-height: 1.875rem;
  margin-left: 0.5rem;
  margin-top: 1rem;
}
.ey-summary-separator {
  width: 1px;
  left: calc(50% - 1px / 2 - 247.5px);
  background: #747480;
  mix-blend-mode: normal;
  opacity: 0.5;
  /* Inside Auto Layout */
  margin: 0px 1.875rem;
  height: 1.4375rem;
  margin-top: 1rem;
}
.ey-summary .emp-icon {
  color: $color-ey-grey-2a !important;
  font-size: 1.9rem !important;
  margin-top: 2rem;
}

.ey-summary .tooltiptext {
  z-index: 2;
}
.ey-summary li {
  font-size: 0.875rem;
}
.ey-summary-tooltip-holder {
  margin-top: 0.8rem;
  margin-left: 0.3rem;
}
