/* file created to support developement process, once dev completed can be removed*/

.work-in-progress {
  border: 1px solid #2e2e3c;
  float: left;
  height: 10rem;
  color: #d8d8d8;
  margin: auto;
  width: 100%;
}
