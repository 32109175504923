@mixin btn-dark-hover {
  &:hover,
  &.hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    &:not(:disabled):not(.disabled) {
      color: $color-ey-yellow;
      border-color: $color-ey-yellow;
      background-color: $color-ey-confident-grey;
    }
  }
}

.btn {
  height: 2.5rem;
  padding-bottom: 0.625rem !important;
  padding-top: 0.625rem !important;
  vertical-align: top;
  line-height: 0.875rem !important;
  &--primary-dark {
    color: $color-ey-confident-grey;
    background-color: $color-ey-yellow;

    @include btn-dark-hover();
  }

  &--secondary-dark {
    color: $color-white;
    border-color: $color-white;
    background-color: $color-ey-confident-grey;

    @include btn-dark-hover();
  }
}

.btn.disabled,
.btn.disabled.active,
.btn.disabled.focus,
.btn.disabled.hover,
.btn.disabled:active,
.btn.disabled:focus,
.btn.disabled:hover,
.btn:disabled,
.btn:disabled.active,
.btn:disabled.focus,
.btn:disabled.hover,
.btn:disabled:active,
.btn:disabled:focus,
.btn:disabled:hover {
  color: #747480;
  background-color: #eaeaf2;
  border: 0.0625rem solid transparent;
  opacity: 1;
  cursor: not-allowed;
}
