/* Empowerment */
$designer-container-height: calc(100vh - 4.25rem - 3.125rem);

/* Primary EY Color Palette */
$color-ey-interaction-overlays: #1a1a24 !default;
$color-ey-confident-grey: #2e2e38 !default;
$color-ey-grey-1: #747480 !default;
$color-ey-grey-2: #c4c4cd !default;
$color-ey-grey-2a: #e1e1e6 !default;
$color-ey-grey-3: #e7e7ea !default;
$color-ey-grey-4: #f6f6fa !default;
$color-ey-grey-5: #fafafc !default;
$color-white: #ffffff !default;
$color-ey-yellow: #ffe600 !default;
$color-table-search: #2e2e3c !default;
$color-table-bkg: #23232f !default;
$color-end-user-form-summary: #eaeaf2 !default;

/* Secondary EY Color Palette */

/* maroon */

$color-maroon-01: #c981b2 !default;
$color-maroon-02: #b14891 !default;
$color-maroon-03: #922b73 !default;
$color-maroon-04: #750e5c !default;
$color-maroon-05: #5a0a42 !default;
$color-maroon-06: #42152d !default;
$color-maroon-07: #351c21 !default;

/* red */

$color-red-01: #ff9a91 !default;
$color-red-02: #ff736a !default;
$color-red-03: #f95d54 !default;
$color-red-04: #ff4136 !default;
$color-red-05: #e0362c !default;
$color-red-06: #b9251c !default;
$color-red-07: #7a130d !default;

/* orange */

$color-orange-01: #ffb46a !default;
$color-orange-02: #ff9831 !default;
$color-orange-03: #ff810a !default;
$color-orange-04: #ff6d00 !default;
$color-orange-05: #f76900 !default;
$color-orange-06: #eb4f00 !default;
$color-orange-07: #bc2f00 !default;

/* green */

$color-green-01: #8ce8ad !default;
$color-green-02: #57e188 !default;
$color-green-03: #34c768 !default;
$color-green-04: #2db757 !default;
$color-green-05: #189d3e !default;
$color-green-06: #168736 !default;
$color-green-07: #13652a !default;

/* teal */

$color-teal-01: #93f0e6 !default;
$color-teal-02: #60e6e1 !default;
$color-teal-03: #42c9c2 !default;
$color-teal-04: #27acaa !default;
$color-teal-05: #109090 !default;
$color-teal-06: #0d7575 !default;
$color-teal-07: #004f4f !default;

/* blue */

$color-blue-01: #87d3f2 !default;
$color-blue-02: #4ebeeb !default;
$color-blue-03: #35a4e8 !default;
$color-blue-04: #188ce5 !default;
$color-blue-05: #1777cf !default;
$color-blue-06: #155cb4 !default;
$color-blue-07: #082c65 !default;
$color-blue-08: #007bff !default;
$color-blue-09: #20202b !default;

/* purple */

$color-purple-01: #9c82d4 !default;
$color-purple-02: #724bc3 !default;
$color-purple-03: #542ea5 !default;
$color-purple-04: #3d108a !default;
$color-purple-05: #240d75 !default;
$color-purple-06: #0a095a !default;
$color-purple-07: #15173e !default;

/* States EY Color Palette */

$color-prompt-label-text: rgba(46, 46, 56, 0.8) !default;
$color-inactive-icon-text: rgba(46, 46, 56, 0.5) !default;
$color-error: #b9251c !default;
$color-warning: #ff9831 !default;
$color-success: #168736 !default;
$color-ey-link: #155cb4 !default;
$color-validation-error: $color-error !default; //refer to Task#159285

/* Colors from GTP UI Guide */

/* Primary Colors */

$color-black: #000000 !default;
$color-yellow: #d5c101 !default;

/* Primary Grays */

$color-ey-dark-gray: #333333 !default;
$color-ey-gray-alt: #646464 !default;
$color-ey-gray: #808080 !default;
$color-ey-gray-tint-1: #999999 !default;
$color-ey-gray-tint-2: #c0c0c0 !default;
$color-ey-gray-tint-3: #f0f0f0 !default;
$color-ey-gray-border-1: #ececec !default;
$color-ey-gray-border-2: #e8e8e8 !default;
$color-ey-gray-text: #3f3f48 !default;

/* Secondary Colors */

$color-ey-red: #f04c3e !default;
$color-ey-link-blue: #336699 !default;
$color-ey-yellow-50: #fff27f !default;

/* Homepage Background color */
$color-bg-homepage: #1c1c27 !default;

/* Tertiary Color */

$color-gray-alt-1: #4a4a4a !default;
$color-gray-alt-2: #d8d8d8 !default;
$color-gray-alt-4: #f6f6f6 !default;

/* Disabled Color */

$color-disabled: rgba(196, 196, 205, 0.35) !default;

/* Shadow Color */

$color-shadow: rgba(130, 138, 145, 0) !default;
$color-shadow-2: rgba(0, 0, 0, 0.15) !default;

/* Border Color */

$color-border: #aaaab1 !default;
$color-border-2: #e7e7ea !default;

/* form  default color */
$form-color-default: $color-ey-confident-grey !default;
$form-bg-default: $color-ey-grey-4 !default;
$form-bg-alternative: $color-ey-grey-2a !default;
$form-border-default: $color-ey-confident-grey !default;
$form-border-disabled: rgba(116, 116, 128, 0) !default;
$form-error-color: $color-red-06 !default;
$form-dpdn-bg-default: $color-white !default;
$form-dpdn-alternative: $color-ey-grey-2a !default;

/* form grey color */
$darkform-color-default: $color-ey-confident-grey !default;
$darkform-bg-default: $color-white !default;
$darkform-bg-alternative: $color-ey-grey-2a !default;
$darkform-border-default: $color-ey-confident-grey !default;
$darkform-border-disabled: $color-ey-grey-1 !default;
$darkform-error-color: $color-red-06 !default;
$darkform-dpdn-bg-default: $color-white !default;
$darkform-dpdn-alternative: $color-ey-grey-2a !default;

/* checkbox default color */
$chk-border-default: $color-ey-confident-grey !default;
$chk-border-disabled: $color-ey-grey-2 !default;
$chk-border-error: $color-red-06 !default;
$chk-color-default: $color-ey-confident-grey !default;
$chk-bg-default: $color-ey-confident-grey !default;
$chk-bg-alternative: $color-ey-grey-2 !default;
$chk-color-disabled: $color-ey-grey-1 !default;
$chk-error-color: $color-red-06 !default;

/* Fonts */

$font-family: 'EYInterstate', 'Noto Sans', Arial, sans-serif !default;
$font-family-fontAwesome: FontAwesome !default;
$font-family-alternate: Georgia !default;
$font-family-bold: 'EYInterstate-Bold', 'Noto Sans', Arial, sans-serif !default;
$font-family-light: 'EYInterstate Light', 'Noto Sans', Arial, sans-serif !default;
$font-family-notoSans: 'Noto Sans', Arial, sans-serif !default;
$font-size: 16px !default;

/* Font Size */
$font-size-1: 0.5rem !default;
$font-size-2: 0.625rem !default;
$font-size-3: 0.75rem !default;
$font-size-4: 0.875rem !default;
$font-size-5: 1rem !default;
$font-size-6: 1.125rem !default;
$font-size-7: 1.25rem !default;
$font-size-8: 1.5rem !default;
$font-size-9: 2rem !default;
$font-size-10: 2.5rem !default;
$font-size-11: 3rem !default;

/* Breakpoints */
$break-point-smaller: 350px !default;
$break-point-small: 576px !default;
$break-point-medium: 768px !default;
$break-point-large: 992px !default;
$break-point-extra-large: 1200px !default;

/* Spacing for padding and margin */
$spacing-0: 0rem !default;
$spacing-01: 0.3125rem !default;
$spacing-02: 0.625rem !default;
$spacing-03: 0.9375rem !default;
$spacing-04: 1.25rem !default;
$spacing-05: 1.5625rem !default;
$spacing-06: 1.875rem !default;
$spacing-07: 2.1875rem !default;
$spacing-08: 2.5rem !default;

$modal-margin-bg-y: 2.5rem !default;
$modal-margin-sm: $spacing-02 !default;

$modal-margin-bg-heavy-y: 2.5rem !default;
$modal-margin-md-heavy: $spacing-04 !default;
$modal-margin-sm-heavy: $spacing-0 !default;
