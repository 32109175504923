.fr-toolbar,
.fr-second-toolbar {
  border-radius: 0 !important;
}

.fr-view {
  p,
  ul,
  ol {
    margin: 0;
  }

  ul + p {
    padding-left: 60px;
  }
}

@mixin img-no-max-width {
  img[style] {
    max-width: content-box;
  }
}
app-preview,
app-submit-response {
  .fr-view {
    @include img-no-max-width();

    ul ul ul ul ul ul ul ul ul ul {
      white-space: nowrap;
      text-overflow: clip;
    }
  }
}

.fr-view.fr-long-image {
  @include img-no-max-width();
}

.fr-view .fr-video {
  width: 100%;
}

.fr-view .fr-video .fr-draggable {
  width: 100%;
}

.fr-video video {
  min-width: 100% !important;
  width: 100% !important;
}

.fr-modal .fr-modal-wrapper {
  border-radius: 0;

  .fr-modal-body {
    padding-bottom: 10px !important;

    .fr-word-paste-modal {
      padding-bottom: 0px !important;

      & > div {
        padding-top: 10px !important;
        border-top: 1px solid #efefef;
        margin-right: -20px;
        margin-left: -20px;

        button:not(.fr-modal-close) {
          @include fonts-col__sub-nav-selected-state;
          color: $color-ey-confident-grey !important;
          transition: all 0.3s ease !important;
          border-radius: 0 !important;
          //all buttons need to have a transparent border to prevent bouncing and support in IE11
          //this will default value for all btn, to override outside btn, use border-color only
          border: 0.0625rem solid transparent !important;
          height: 2.813rem !important;
          vertical-align: middle !important;
          padding-top: 0.0625rem !important;
          padding-left: 1.25rem !important;
          padding-right: 1.25rem !important;
          padding-bottom: 0 !important;
          /*   buttons should not be smaller then 6.875rem */
          min-width: 6.875rem !important;
          cursor: pointer !important;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;

          /* disabled buttons start ------------------------------*/
          &.disabled,
          &:disabled {
            &,
            &:hover,
            &.hover,
            &:focus,
            &.focus,
            &:active,
            &.active {
              color: $color-ey-grey-1 !important;
              background-color: #eaeaf2 !important; //no token
              border: 0.0625rem solid transparent !important; /* all buttons need to have a transparent border to prevent bouncing and support in IE11*/
              opacity: 1 !important;
              cursor: not-allowed !important;
            }
          }

          &.fr-remove-word {
            background: none !important;
            color: $color-ey-confident-grey !important;
            border-color: $color-ey-confident-grey !important;
          }

          &.fr-keep-word {
            color: $color-ey-confident-grey !important;
            background-color: $color-ey-yellow !important;
            margin-right: 20px;
          }

          &:hover,
          &.hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            color: $color-white !important;
            background-color: $color-ey-confident-grey !important;
            border-color: 0.0625rem solid transparent !important; //default - prevent bouncing
            outline: none !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
          }
        }
      }
    }
  }
}

.letter-sheet {
  .fr-wrapper {
    border: none !important;
  }

  .fr-second-toolbar {
    display: none !important;
  }

  .fr-element {
    min-height: 1.5rem !important;
    padding: 0.125rem !important;
  }

  .fr-toolbar {
    border: none;
  }

  .fr-newline {
    background: none;
  }
  .fr-element.fr-view {
    font-size: 11px;
  }
}

.modal__body {
  .fr-wrapper {
    .fr-element.fr-view p {
      font-family: Calibri !important;
    }
  }
}

.full_screen {
  .fr-second-toolbar {
    display: none !important;
  }
  .fr-wrapper {
    height: 28.563rem !important;
    max-height: 28.563rem !important;
    overflow: auto !important;
  }
}

.hint-tab {
  .fr-second-toolbar {
    display: none !important;
  }
}

h1.hero-title {
  font-family: EYInterstate !important;
  font-size: 3rem !important;
}

.paragraph-small {
  font-family: EYInterstate Light !important;
  font-size: 0.875rem !important;
}

.fr-toolbar.fr-desktop.fr-top.fr-basic.fr-sticky-off {
  position: static;
}

.fr-view p {
  margin-bottom: 0.75rem;
}

.fr-box.fr-basic.fr-top {
  .fr-sticky-dummy {
    display: none !important;
  }
}

.fr-toolbar.fr-desktop.fr-top.fr-basic.fr-sticky-on {
  position: relative !important;
}
