.navigation-bar-primary {
  ul {
    height: 3rem;
    padding-left: 0.75rem;

    li {
      button {
        &.active {
          background-color: $color-ey-interaction-overlays !important;
        }
      }
    }
  }

  .navigation-bar-primary__nav-link {
    font-family: $font-family !important;
    font-size: $font-size-4 !important;
    line-height: normal !important;
  }
}

nav.navigation-bar-secondary.tetrary-reverse {
  ul {
    padding-left: 1.25rem !important;
  }
}
